import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom';
import { Modal, Input, Layout, Row, Col, Button, List, message, Typography, Pagination, Select, Space } from 'antd';
import { IoIosInformationCircleOutline } from 'react-icons/io'
//componentes
import FloatingButton from './../Widgets/FloatingButton/FloatingButton';
import { IconSearch, IconSelectArrow, IconPlus } from './../Widgets/Iconos'
import ModalUsuarioDetalle from "./usuarioDetalle";
import ModalUsuario from "./ModalUsuarios";
//css
import '../global/css/Usuarios.css';

const axios = require("axios").default;
const { confirm } = Modal;
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;

export default class Usuarios extends Component {

    state = {
        loading: false,
        list: [],
        search: '',
        redirect: false,
        niveles: [],

        filtroNivel: "",

        /**
         * Control de la paginación.
         * */
        currentPage: 1,
        itemCount: 1,
        perPage: 10,


        detalleVisible: false,
        usuSeleccionado: "",
        edicionVisible: false,

    };


    /**
     * @memberof Usuarios
     *
     * @method   componentDidMount
     * @description  Metodo que carga los datos a la vista
     *
     **/
    componentDidMount() {
        this.receivedData();
        this.GetRoles();

        if (this.props.match.params.id) {
            this.setState({
                detalleVisible: true,
                usuSeleccionado: this.props.match.params.id
            });
        }
    }


    GetRoles = async () => {
        axios.get("/roles", {
            headers: { Authorization: sessionStorage.getItem("token") }
        })
            .then(res => {
                this.setState({ niveles: res.data })
            })
            .catch(res => {
                console.log("lista de roles consulta", res);
            })
    }


    /**
     * @memberof Usuarios
     *
     * @method   receivedData
     * @description  Metodo que realiza el paginado de los usuarios
     * @param page (Number) del paginado a buscar 
     *
     **/
    receivedData = async (page, search = this.state.search) => {
        let { filtroNivel } = this.state;
        this.setState({ loading: false })
        axios.get('/usuarios', {
            headers: { Authorization: sessionStorage.getItem("token") },
            params: {
                page: page,
                limit: 10,
                search: search,
                filtroNivel

            }
        })
            .then(res => {
                const dataList = res.data.data.itemsList
                const paginator = res.data.data.paginator;

                this.setState({
                    loading: false,
                    list: dataList,

                    /**paginado */
                    currentPage: paginator.currentPage,
                    itemCount: paginator.itemCount,
                    perPage: paginator.perPage,
                })
            })
            .catch(error => {
                console.log(error.response);
                this.setState({ loading: false })
                Modal.error({
                    title: "Ha ocurrido un error al cargar los datos"
                });
            });
    }


    /**
     * @memberof Usuarios
     *
     * @method   searchData
     * @description  Metodo que realiza la busqueda de usuarios
     * @param values String   con la info a buscar, por defecto cadena vacia para busquedas
     *
     **/
    searchData = (values) => {
        this.setState({ search: values });
        this.receivedData(1, values);

    }


    /**
    * @memberof Usuarios
    *
    * @method   handlePageClick
    * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
    *
    **/
    handlePageClick = page => {
        this.receivedData(page);
    };


    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/admin/usuarios" />;
        }
    };


    abrirModalDetalle = async (regId) => {
        this.setState({
            detalleVisible: true,
            usuSeleccionado: regId
        });
    };


    abrirModalCreacion = async () => {
        this.setState({
            creacionVisible: true,
            modalTitle: 'Crear',
        });
    };

    /**
   * @memberof Usuarios
   *
   * @method   abrirModalEdicion
   * @description  Metodo que abre el modal para edicion y prepra los datos para pasarlos
   *
   **/
    abrirModalEdicion = (usuario) => {
        usuario.tipoUsuario = usuario.roles_id._id
        console.log(usuario)
        this.setState({ usuario: usuario, creacionVisible: true, modalTitle: 'Editar' })
    };


    handleCancel = e => {
        this.setState({
            creacionVisible: false,
            detalleVisible: false,
            usuario: undefined,
        });

        setTimeout(() => {
            this.receivedData(1);
        }, 250);
    };


    UpdateFiltroNivel = async (value) => {
        var nivel = (value === undefined) ? "" : value;
        this.setState({ filtroNivel: nivel })
        setTimeout(() => {
            this.receivedData(1);
        }, 250);

    }



    render() {
        const { list, } = this.state;

        const showDeleteConfirm = (item) => {
            const user_name = item.currentTarget.name;
            const user_id = item.currentTarget.id;
            const metodo = this.handleCancel;

            confirm({
                title: 'Eliminar registro',
                icon: <IoIosInformationCircleOutline />,
                content: '¿Estas seguro que deseas eliminar a ' + user_name + ' ?',
                okText: 'Eliminar',
                okType: 'danger',
                cancelText: 'Cancelar',
                onOk() {

                    axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
                    axios({
                        method: 'delete',
                        url: '/usuarios/delete',
                        headers: { Authorization: sessionStorage.getItem('token') },
                        data: { id: user_id }
                    })
                        .then((response) => {
                            message.success("Se elimino registro correctamente!");
                            setTimeout(() => {
                                metodo();
                            }, 300);
                        })

                        .catch((error) => {
                            console.log("error al borrar registro", error.response);
                        })
                },
            });
        }
        return (
            <Layout className="dashboard-section-layout">
                {this.renderRedirect()}
                <Content className="dashboard-section-content">
                    <div className="divForm">
                        <Row className="pd-1 row-enc-vista" justify="space-around" >
                            <Col xs={24} md={24} lg={24} xl={6} xxl={6}  >
                                <p className="nombreVista">Usuarios</p>
                            </Col>
                            <Col xs={24} md={12} lg={12} xl={10} xxl={10}  >
                                <Search
                                    prefix={<IconSearch />}
                                    placeholder="Buscar Usuarios"
                                    className="input-search input-filtro"
                                    onSearch={this.searchData}
                                />
                            </Col>
                            <Col xs={24} md={10} log={10} xl={7} xxl={7} className="filters-drop ">
                                <Select
                                    placeholder="Nivel"
                                    allowClear
                                    className="selec-niveles"
                                    onChange={e => this.UpdateFiltroNivel(e)}
                                >
                                    {this.state.niveles.map(function (rol, index) {
                                        return <Option className="opc-niveles" value={rol._id} title={rol.nombre}>{rol.nombre}</Option>
                                    })
                                    }
                                </Select>
                                <IconSelectArrow />
                            </Col>
                        </Row>

                        <Row   >
                            <List
                                className="list-item-access blackTheme"
                                dataSource={list}
                                loading={this.state.loading}
                                header={
                                    <Row className="list-header"  >
                                        <Col span={1} className="item-access-row-column">
                                        </Col>
                                        <Col span={4} className="item-access-row-column">
                                            <Text ellipsis >Nombre</Text>
                                        </Col>
                                        <Col span={5} className="item-access-row-column">
                                            <Text ellipsis >Email</Text>
                                        </Col>
                                        <Col span={4} className="item-access-row-column">
                                            <Text ellipsis >Télefono</Text>
                                        </Col>
                                        <Col span={4} className="item-access-row-column">
                                            <Text ellipsis >Rol</Text>
                                        </Col>
                                    </Row>
                                }
                                renderItem={item => {
                                    return <List.Item className="div-accesos-list ">
                                        <Row className="item-access-row"  >
                                            <Col span={1} className="item-access-row-column">
                                                <Text ellipsis className="muesca"></Text>
                                            </Col>
                                            <Col span={4} className="item-access-row-column">
                                                <Text ellipsis >{item.nombre}</Text>
                                            </Col>
                                            <Col span={5} className="item-access-row-column">
                                                <Text ellipsis >{item.email}</Text>
                                            </Col>
                                            <Col span={4} className="item-access-row-column">
                                                <Text ellipsis >{item.telefono}</Text>
                                            </Col>
                                            <Col span={4} className="item-access-row-column">
                                                <Text ellipsis >{(item.roles_id !== undefined) ? item.roles_id.nombre : 'Sin Rol Asignado'}</Text>
                                            </Col>
                                            <Col span={6} className="item-access-row-column">
                                                <Space size="middle">
                                                    <Button type="link" className="accion-text" title="Ver" name={item.nombreCompleto} id={item._id} onClick={() => { this.abrirModalDetalle(item._id) }} >Ver</Button>
                                                    <Button type="link" className="accion-text" title="Editar" onClick={() => { this.abrirModalEdicion(item) }}>Editar</Button>
                                                    <Button type="link" className="accion-text" title="Eliminar" name={item.nombre} id={item._id} onClick={showDeleteConfirm} >Eliminar</Button>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                }}
                                pagination={false}
                            />
                            <Row className="pd-1 " style={{ width: "100%", float: "left", textAlign: "right", display: "block" }}  >
                                <Pagination
                                    total={this.state.itemCount}
                                    current={this.state.currentPage}
                                    className="pagination-table"
                                    onChange={this.handlePageClick}
                                />
                            </Row>
                        </Row>
                    </div>
                </Content>

                <ModalUsuarioDetalle
                    id={this.state.usuSeleccionado}
                    visible={this.state.detalleVisible}
                    onCancel={this.handleCancel}
                />

                <ModalUsuario
                    visible={this.state.creacionVisible}
                    onCancel={this.handleCancel}
                    usuario={this.state.usuario}
                    modalTitle={this.state.modalTitle}
                />

                <FloatingButton onClick={this.abrirModalCreacion} />

            </Layout >

        );
    }
}