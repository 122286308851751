//Dependencias
import React, { Component } from 'react';
import { Button, Layout, Input, Row, Col, List, Modal, Select, Pagination, Carousel, Image, Typography } from 'antd';
import { Link, } from 'react-router-dom';
import '../../global/css/Database.css';
import { IconEye, IconSearch, IconSelectArrow, IconPlus } from './../../Widgets/Iconos'
import ModalAutorizada from '../Modales/ModalAutorizada';
import ModalNoAutorizada from '../Modales/ModalNoAutorizada';
import ModalAutorizar from '../Modales/ModalAutorizar'

const axios = require('axios');
const { Content } = Layout;
const { Search } = Input
const { Option } = Select;
const {Text} = Typography


/**
 *
 * @class Database
 * @extends {Component}
 */
export default class Database extends Component {

    state = {

        filtroTipo: "",
        filtroCasa: "",
        filtroAsunto: "",
        filtroEstatus: "",

        pageRequests: 1,
        quantityRequests: 1,
        totalRequests: 10,
        dataRequests: [],
        pageAccess: 1,
        quantityAccess: 0,


        filterRequests: [],
        loadingRequests: false,


        data: [],
        totalAccess: 0,

        filterAccess: [],
        loadingAccess: false,

        datefilter: null,


        detalleVisible: false,
        admision_id: null,

        nuevaAdmisionVisible: false,

        casas: [],
        asuntos: [],

        /**paginado */
        currentPage: 1,
        itemCount: 10,
        perPage: 10,
        pageCount: 10,

        imageVisible: false,
        imagePreview: [],



        // modales solicitudes
        showAutorizada: false,
        showNoAutorizada: false,
        showSolicitud: false,
        ModalAutorizar:false,
    }

    /**
     *
     * @method componentDidMount
     * @description Obtiene todos los registros al cargar la vista 
     * */

    componentDidMount() {
        this.getCasas();
        this.getAsuntos();
        this.receivedData(1);
    }



    /**
    * @method receivedData
    * @description  Consulta de admisiones para llenar tabla
    **/
    receivedData = async (page, search = this.state.search) => {
        this.setState({ loading: true });
        let {
            filtroTipo,
            filtroCasa,
            filtroAsunto,
            filtroEstatus } = this.state;

        axios.get('/admisiones/paginacion',
            {
                headers: { Authorization: sessionStorage.getItem("token") },
                params: {
                    page: page,
                    search: search,
                    filtroTipo,
                    filtroCasa,
                    filtroAsunto,
                    filtroEstatus
                }
            })
            .then(res => {
                const dataList = res.data.data.itemsList
                const paginator = res.data.data.paginator;

                this.setState({
                    loading: false,
                    data: dataList,

                    /**paginado */
                    currentPage: paginator.currentPage,
                    itemCount: paginator.itemCount,
                    perPage: paginator.perPage,
                })
            })
            .catch(res => {
                console.log("error", res.response);

            })
            .finally(f => {
                this.setState({ loading: false })
            });
    }


    /**
    * @method searchData
    * @description  Se establece valor busqueda
    **/
    searchData = (values) => {
        this.setState({ search: values });
        this.receivedData(1, values);
    }


    /**
     *
     * @method getAsuntos
     * @description  Se consultan los asuntos para filtro
     */

    getAsuntos = () => {
        axios.get('/asuntos', {
            params: { page: 1, limit: 1000 }
        })
            .then((res) => {
                console.log(res)
                this.setState({ asuntos: res.data.data.itemsList });
            })
            .catch((error) => {
                console.log('no se pudo cargar los asuntos', error.response);
            });
    }


    /**
     *
     *
     * @memberof getCasas
     * @description Se consultan las casas para filtro
     */
    getCasas = () => {
        axios.get('casas')
            .then(res => {
                console.log(res)

                this.setState({ casas: res.data.data })
            })
            .catch(error => {
                console.log('error al cargar casas', error)
            });
    }


    /**
    * @method UpdateFiltroTipo
    * @description  Se establece valor filtro tipo
    **/
    UpdateFiltroTipo = (value) => {
        var tipo = (value === undefined) ? "" : value;
        this.setState({ filtroTipo: tipo })
        setTimeout(() => {
            this.receivedData(1);
        }, 250);

    }


    /**
    * @method UpdateFiltroEstatus
    * @description  Se establece valor filtro estatus
    **/
    UpdateFiltroEstatus = (value) => {
        var estatus = (value === undefined) ? "" : value;
        this.setState({ filtroEstatus: estatus })
        setTimeout(() => {
            this.receivedData(1);
        }, 250);
    }

    /**
    * @method UpdateFiltroCasa
    * @description  Se establece valor filtro casa
    **/
    UpdateFiltroCasa = (value) => {
        var casa = (value === undefined) ? "" : value;
        this.setState({ filtroCasa: casa })
        setTimeout(() => {
            this.receivedData(1);
        }, 250);
    }

    /**
    * @method UpdateFiltroAsunto
    * @description  Se establece valor filtro asunto
    **/
    UpdateFiltroAsunto = (value) => {
        var asunto = (value === undefined) ? "" : value;
        this.setState({ filtroAsunto: asunto })
        setTimeout(() => {
            this.receivedData(1);
        }, 250);
    }


    /**
    * @method handlePageClick
    * @description  Cambio pagina para consulta
    **/
    handlePageClick = page => {
        this.receivedData(page);
    };


    /**
    * @method abrirModalCreacion
    * @description  Se abre modal tipo creación
    **/
    abrirModalCreacion = async () => {
        this.setState({
            nuevaAdmisionVisible: true,
        });
    };


    /**
    * @method handleCancel
    * @description  Se cierra modal 
    **/
    handleCancel = e => {
        this.setState({
            nuevaAdmisionVisible: false,
            detalleVisible: false,
        });

        setTimeout(() => {
            this.receivedData(1);
        }, 250);
    };


    renderEstatus = (status) => {
        let color, texto;
        if (status !== undefined) {
            switch (status) {
                case 0:
                    color = "#7ED321";
                    texto = "Rechazada";
                    break;

                case 1:
                    color = "#7ED321";
                    texto = "Nueva";
                    break;

                case 2:
                    color = "#FF715B";
                    texto = "Aceptada";
                    break;
                default:
                    color = "#FB0000";
                    break;
            }
        }
        return texto;
    }


    /**
     *
     *
     * @memberof Database
     */
    viewImage = (image) => {
        this.setState({ imageVisible: true, imagePreview: image })
    }


    /**
     *
     *
     * @memberof Database
     * @description Abre el modal correspondiente al registro
     * @param status Integer
     * @param id ObjectId
     */
    showExpediente = (status, id) => {
        console.log('status', status);

        this.state.admision_id = id;
        switch (status) {
            case 0:
                this.state.showNoAutorizada = true
                break;
            case 1:
                this.state.ModalAutorizar = true
                break;

            case 2:
                this.state.showAutorizada = true
                break;
        }

    }


    hideExpediente = () => {
        this.setState({
            showNoAutorizada : false,
            showSolicitud   : false,
            showAutorizada   : false,
            ModalAutorizar:false,
        })
      
    }
    render() {
        let {
            loadingRequests,
            data,
        } = this.state;

        return (
            <Layout className="dashboard-section-layout">
                <Content className="dashboard-section-content">
                    <div className="divForm">
                        <Row className="pd-1 row-enc-vista" justify="start">
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <p className="nombreVista">Base de Datos</p>
                            </Col>
                            <Col xs={24} md={12} lg={10} xl={10} xxl={10}>
                                <Search prefix={<IconSearch />} placeholder="Buscar en Base de Datos" className="input-search" onSearch={this.searchData} />
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={14} xxl={14} >
                                <Row justify="space-around">
                                    <Col xs={24} sm={12} md={6} xl={6} className="filters-drop">
                                        <Select placeholder="Tipo" allowClear onChange={e => this.UpdateFiltroTipo(e)}>
                                            <Option value={0}>Peatón</Option>
                                            <Option value={1}>Vehiculo</Option>
                                        </Select>
                                        <IconSelectArrow />
                                    </Col>
                                    <Col xs={24} sm={12} md={6} xl={6} className="filters-drop">
                                        <Select placeholder="Casa" allowClear onChange={e => this.UpdateFiltroCasa(e)} >
                                            {this.state.casas.map(casa => {
                                                return <Option value={casa._id}>{casa.nombre}</Option>
                                            })}

                                        </Select>
                                        <IconSelectArrow />
                                    </Col>
                                    <Col xs={24} sm={12} md={6} xl={6} className="filters-drop">
                                        <Select placeholder="Asunto" allowClear onChange={e => this.UpdateFiltroAsunto(e)}>
                                            {this.state.asuntos.map(asunto => {
                                                return <Option value={asunto._id}>{asunto.nombre}</Option>
                                            })}
                                        </Select>
                                        <IconSelectArrow />
                                    </Col>
                                    <Col xs={24} sm={12} md={6} xl={6} className="filters-drop">
                                        <Select placeholder="Estatus" allowClear onChange={e => this.UpdateFiltroEstatus(e)}>
                                            <Option value={0}>Rechazada</Option>
                                            <Option value={1}>Nueva</Option>
                                            <Option value={2}>Aceptada</Option>
                                        </Select>
                                        <IconSelectArrow />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <List
                                className="list-item-access blackTheme"
                                dataSource={data}
                                loading={loadingRequests}
                                renderItem={item => {
                                    return <List.Item className="div-accesos-list ">
                                        <Row className="item-access-row item-db-row"  >
                                            <Col span={1} className="muesca" style={{
                                                background : (item.status == 0) ?  "#C51F29": (item.status == 1) ? '#EBFF00': "#04FAA1" 
                                            }}></Col>
                                            <Col span={3} className="item-access-row-column">
                                                <Text ellipsis >  {item.datos}</Text>
                                            </Col>
                                            <Col span={6} className="item-access-row-column">
                                                <Text ellipsis >  {item.asunto_id?.nombre}</Text>
                                            </Col>
                                            <Col span={3} className="item-access-row-column">
                                                <Text ellipsis >  {item.casa_id?.nombre}</Text>
                                            </Col>
                                            <Col span={2} className="item-access-row-column">
                                                {this.renderEstatus(item.status)}
                                            </Col>
                                            <Col span={6} className="item-access-row-column">
                                                <Text ellipsis >  {item.descripcion}</Text>
                                            </Col>
                                            {(item.tipo === 0) ?
                                                <Col span={1} className="item-access-row-column">
                                                    <Button type="link" title={(item?.visitante_id?.comprobantes.length > 0) ? "Ver" : "Sin Foto"} onClick={() => this.viewImage(item.visitante_id.comprobantes)} disabled={!(item?.visitante_id?.comprobantes.length > 0)} ><IconEye /> </Button>
                                                </Col>
                                                : <Col span={1} className="item-access-row-column"></Col>}
                                            <Col span={2} className="item-access-row-column">
                                                <Link title="Ver Expediente" className="RecurIcon" style={{ fontSize: "10px" }} onClick={() => this.showExpediente(item.status, item._id)}> Ver Expediente </Link>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                }}
                                pagination={false}
                            />
                        </Row>

                        <Row className="pd-1 " justify="end" >
                            <Pagination
                                total={this.state.itemCount}
                                current={this.state.currentPage}
                                className="pagination-table"
                                onChange={this.handlePageClick}
                            />
                        </Row>
                    </div>
                </Content>



                <Button className="btnAddPlus" title="Nuevo registro" onClick={this.abrirModalCreacion}>
                    <IconPlus />
                </Button>

                {/* Modal Nuevo Acceso */}
                <ModalAutorizada asistencia={this.state.admision_id} hideModal={this.hideExpediente} visible={this.state.showAutorizada}/>
                <ModalNoAutorizada asistencia={this.state.admision_id} hideModal={this.hideExpediente} visible={this.state.showNoAutorizada}/>
                
                <ModalAutorizar
                    asistencia={this.state.admision_id}
                    visible={this.state.ModalAutorizar}
                    hideModal={this.hideExpediente}
                    onFinish={(success) => {
                        console.log('success', success);
                        if (success == true) {
                            this.setState({ showAutorizada: true,ModalAutorizar:false })
                            this.receivedData()
                        } else {
                            this.setState({ showNoAutorizada: true,ModalAutorizar:false })
                            this.receivedData()
                        }
                    }}
                />
                <Modal className="modal-aide top_up"
                 visible={this.state.imageVisible} 
                 onCancel={() => this.setState({ imageVisible: false })} footer={false} title={false}>
                    <Carousel>
                        {this.state.imagePreview.map(imagen => {
                            return <Image style={{ width: '100%' }} wrapperClassName="center" src={axios.defaults.baseURL + 'upload/' + imagen} preview={false} />
                        })}
                    </Carousel>
                </Modal>
            </Layout>
        );
    }
}
