//Dependencias
import React, { Component } from "react";
import { Input, Layout, Select, Row, Col, Button, PageHeader } from 'antd';
import { Icon } from '@ant-design/compatible';


import { IconPlus } from '../Iconos';

import propTypes from 'prop-types';


//assets
import './FloatingButton.css';



/**
 *
 *
 * @class QRs
 * @extends {Component}
 */
 export default class FloatingButton extends Component {
    
    static propTypes = {
        onClick: propTypes.func
    }
    
    render() {
        return <button {...this.props}
            className="floating-button"
            >
                <IconPlus />
            </button>
    }
}
