//Dependencias
import React, { Component } from "react";
import { Layout, List, Row, Col, Modal, message, Pagination, Button, Space, PageHeader, Typography } from 'antd';

import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import ModalAsuntoForm from './ModalAsunto';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IconClose } from '../../Widgets/Iconos';

//assets
import '../../global/css/asuntos.css';


const axios = require('axios');
const { confirm } = Modal;
const { Content } = Layout;
const { Title } = Typography;


export default class AsuntosList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            asunto: undefined,

            data: [],
            loading: true,

            currentPage: 1,
            itemCount: 1,
            pageCount: 1,
            search: ''

        }
    }

    /**
     * @memberof AsuntosList
     */
    componentDidMount() {
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
        this.getAsuntos();

    }

    /**
     * @param {*} page
     * @param {*} [search=this.state.search]
     * @memberof AsuntosList
     */
    getAsuntos(page, search = this.state.search) {
        axios.get('/asuntos', {
            params: {
                page: page,
                limit: 10,
                search: search
            }
        })
            .then(res => {
                const dataList = res.data.data.itemsList
                const data = res.data.data.paginator;
                console.log(res)

                this.setState({
                    data: dataList,
                    /**paginado */
                    itemCount: data.itemCount,
                    perPage: data.perPage,
                    pageCount: data.pageCount,
                    currentPage: data.currentPage,
                })
                console.log("ok", dataList);
            })
            .catch(error => {
                console.log(error.response);
                Modal.error({
                    title: "Ha ocurrido un error al cargar los datos"
                });
            })
            .finally(f => {

                this.setState({ loading: false })
            });
    }

    /**
    * @method showModal
    * @description abre el modal para editar  
    **/
    showModal = (item) => {
        this.setState({
            asunto: item,
            modalShow: true
        })
    }

    /**
    * @method showDeleteConfirm
    * @description  Para cada row mostrar un modal de confirmacion para eliminar el registro, la eliminacion se realiza mediante una peticion al servidor
    *
    * @params item (row)
    **/
    showDeleteConfirm = (item) => {
        const asunto = item.currentTarget.name;
        const asunto_id = item.currentTarget.id;
        const esto = this;
        confirm({
            title: 'Eliminar Asunto',
            icon: <ExclamationCircleOutlined />,
            content: '¿Estás seguro que deseas eliminar el Asunto ' + asunto + ' ?',
            okText: 'Continuar',
            okType: 'danger',
            cancelText: 'Cancelar',

            onOk() {
                axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
                axios({
                    method: 'delete',
                    url: '/asuntos/delete',
                    headers: { Authorization: sessionStorage.getItem('token') },
                    data: { id: asunto_id }
                })
                    .then((res) => {
                        esto.getAsuntos(esto.state.currentPage);
                        message.success("Asunto eliminado, ¡exitosamente!");

                    })
                    .catch((error) => {
                        console.log("error al borrar el Asunto", error);
                        message.error('¡Ooops! NO se ha eliminado el Asunto');
                    })
            },
        });
    }




    /**
     * @memberof AsuntosList
     * @description Actualiza la tabla
     */
    reloadTable = () => {
        this.setState({ modalShow: false, asunto: undefined });
        this.getAsuntos(this.state.currentPage);
    }


    /**
     * @memberof AsuntosList
     * @description Cambia la pagina para cargar nuevos datos
     */
    handlePageClick = page => {
        this.getAsuntos(page);
    };



    render() {
        const { data, loading } = this.state;
        return (
            <Layout className="dashboard-section-layout">
                <PageHeader className="dashboard-section-header nombreVista" title="Asuntos" ></PageHeader>
                <Content className="dashboard-section-content">
                    <div className="divForm">
                        <Row className="pd-1 "  >
                            <List
                                className="list-item-access blackTheme"
                                dataSource={data}
                                loading={loading}
                                pagination={false}
                                renderItem={item => (

                                    <List.Item className="div-accesos-list">
                                        <Row className="item-access-row" style={{ minheight: '45px', minWidth: "300px" }}>
                                            <Col span={1} className="muesca" ></Col>
                                            <Col xs={10} md={13} lg={16} xl={18} className="item-access-row-column">
                                                {item?.nombre}
                                            </Col>
                                            <Col span={5} className="item-access-row-column">
                                                <Space size="middle" >
                                                    <Button type="link" className="accion-text" onClick={() => this.showModal(item)}>Editar</Button>
                                                    <Button type="link" className="accion-text" id={item._id} name={item.nombre} onClick={this.showDeleteConfirm}>Eliminar</Button>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </Row>

                        <Row className="pd-1 " justify="end" >
                            <Pagination
                                total={this.state.itemCount}
                                current={this.state.currentPage}
                                className="pagination-table"
                                onChange={this.handlePageClick}
                                pageSize={10}
                                showSizeChanger={false}
                            />
                        </Row>
                    </div>
                </Content>


                <Modal
                    visible={this.state.modalShow}
                    onCancel={() => this.setState({
                        modalShow: false,
                        asunto: undefined
                    })}
                    destroyOnClose={true}
                    closable={false}
                    footer={false}
                    className="modal-aide"
                >
                    <Row>
                        <Col span={24} className='modal-header'>
                            <Button className="btn-close-modal" onClick={() => this.setState({ modalShow: false, asunto: undefined })}>
                                <IconClose />
                            </Button>
                            <img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal" />
                        </Col>
                        <Col span={24} className="center mt-3">
                            <Title level={3} className="modal-title">Crear Asunto</Title>
                        </Col>
                    </Row>

                    <Row align="center">
                        <Col span={20}>
                            <ModalAsuntoForm asunto={this.state.asunto} close={this.reloadTable} />
                        </Col>
                    </Row>
                </Modal>
                <FloatingButton onClick={() => this.setState({ modalShow: true })} />
            </Layout>
        );
    }
}
