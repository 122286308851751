//Dependencias
import React, {Component} from 'react';
 
class Page404 extends Component{
    render(){
        return(
            <div className="Page404">
                <h1>Page404 Page</h1>
            </div>
        );
    }
}

export default Page404;