import React, { Component } from 'react';
import { Modal, Row, Col, Button, Typography, Form, Input } from 'antd';
//componentes
import { IconSuccess } from '../../Widgets/Iconos'
//css
import '../../global/css/modalesTEMP.css'

const { Title, Text } = Typography;
const moment = require('moment');
const axios = require('axios');

class ModalAutorizada extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {}
		}
	}


	/**
	 *
	 *
	 * @memberof ModalAutorizada
	 */
	componentDidMount() {
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token');
		this.getAdmision()


	}

	
	/**
	 *
	 *
	 * @memberof ModalAutorizada
	 * @description Metodo que obtiene los datos de la solicitud
	 * @param id ObjectId
	 */
	getAdmision = (id = this.props.asistencia) => {
		this.setState({ loading: true })
		axios.get('/admision/autorizar', {
			params: {
				id
			}
		})
			.then(({ data }) => this.setState({ form: data.data }))
			.catch(error => { })
			.finally(() => this.setState({ loading: false }))
	}


	/**
	 *
	 *
	 * @memberof ModalAutorizada
	 * @description Actualiza el estatus de admision para aprobar/rechazar
	 * @param value {}
	 */
	procederAdmision = (value) => {
		this.setState({ loading: true })
		axios.put('/admision/update', {
			id: this.props.asistencia,
			status: (value == true) ? 2 : 0
		})
			.then(() => {
				this.props.onFinish(value)
			})
			.catch(error => {
				this.props.onFinish(false)
			})
			.finally(() => this.setState({ loading: false }))

	}
	render() {
		const { form = {} } = this.state
		return (
			<div>
				<Row>
					<Col span={24} className='modal-header'>
						<img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal" />
					</Col>
					<Col span={24} className="center mt-1">
						<Title level={3} className="modal-title">{(form?.tipo == 1) ? "Entrada Vehiculo" : "Entrada Peaton"} </Title>
					</Col>
				</Row>

				<Form className="modal-form" >
					{form?.tipo === 0 ? <div>
						<Row align="center ">
							<Col span={11} >
								<Form.Item name="nombre" label="Nombre Completo">
									<Text className="modal-text">{(form?.visitante_id?.nombre)}</Text>
								</Form.Item>
							</Col>
							<Col span={11} offset={1} >
								<Form.Item name="time" label="Fecha y Hora" >
									<Text className="modal-text">{moment(form?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text>
								</Form.Item>
							</Col>
						</Row>
						<Row align="center">
							<Col span={11}>
								<Form.Item name="casa" label="Casa">
									<Text className="modal-text">{form?.casa_id?.nombre}</Text>
								</Form.Item>
							</Col>
							<Col span={11} offset={1}>
								<Form.Item name="asunto" label="Asunto">
									<Text className="modal-text">{form.asunto_id?.nombre}</Text>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={11} style={{ marginLeft: '12px' }}>
								<Form.Item name="foto" label="Foto">
									<img
										width={100}
										src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
									/>
								</Form.Item>
							</Col>
						</Row>
					</div>
						:
						<div>
							<Row align="center ">
								<Col span={11} >
									<Form.Item name="casa" label="Casa" >
										<Text className="modal-text">{form.casa_id?.nombre}</Text>
									</Form.Item>
								</Col>
								<Col span={11} offset={1} >
									<Form.Item name="time" label="Fecha y Hora">
										<Text className="modal-text">{moment(form?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text>
									</Form.Item>
								</Col>
							</Row>
							<Row align="center">
								<Col span={11}>
									<Form.Item name="descripción" label="Vehiculo">
										<Text className="modal-text">{form?.descripcion}</Text>
									</Form.Item>
								</Col>
								<Col span={11} offset={1}>
									<Form.Item name="placas" label="Placas">
										<Text className="modal-text">{form?.datos}</Text>
									</Form.Item>
								</Col>
							</Row>
						</div>}
				</Form>
				<Row className="center" className="buttons-container">
					<Button type="primary" htmlType="submit" className="btn- btn-denied" onClick={() => this.procederAdmision(false)}>
						Rechazar Solicitud
					</Button>
					<Button type="primary" htmlType="submit" className="btn- btn-accept" onClick={() => this.procederAdmision(true)}>
						Aprobar Solicitud
					</Button>

				</Row>
			</div>

		);
	}
}




export default function (props) {

	return <Modal
		className="modal-aide"
		visible={props.visible}
		closable={false}
		footer={false}
		destroyOnClose={true}
		onCancel={props.onCancel}
		width={650}
	>
		<ModalAutorizada {...props} />
	</Modal>

}
