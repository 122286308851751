import React, { Component } from "react";
import { Table, Form, Input, Layout, Row, Col, Modal, Spin, message, PageHeader, Space, Button } from 'antd';
import { FaPlus, FaTrashAlt, FaEdit } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import moment from 'moment';

const { Header, Content } = Layout;
const { confirm } = Modal;
const axios = require("axios").default;
const { Search } = Input;


/**
 * @method showDeleteConfirm
 * @description  Para cada row mostrar un modal de confirmacion
 *  para eliminar el registro, 
 * la eliminacion se realiza mediante una peticion al servidor
 *
 * @params item (row)
 **/

const showDeleteConfirm = (item) => {
  const _name = item.currentTarget.name;
  const _id = item.currentTarget.id;

  confirm({
    title: 'Eliminar registro',
    icon: <ExclamationCircleOutlined />,
    content: '¿Estas seguro que deseas eliminar ' + _name + ' ?',
    okText: 'Continuar',
    okType: 'danger',
    cancelText: 'Cancelar',

    onOk() {
      axios({
        method: 'delete',
        url: '/reportes/delete',
        headers: { Authorization: sessionStorage.getItem('token') },
        data: { id: _id }
      })
        .then((response) => {

          message.success('Registro eliminado correctamente');
          document.getElementsByClassName('ant-input-search-button')[0].click()
          //this.receivedData()
          //window.location.reload();
        })
        .catch((error) => {
          console.log("error al borrar registro", error.response);
        })
    },
  });
}

const columns = [
  {
    title: 'Nombre',
    dataIndex: 'nombre',
    key: 'nombre',
  },
  {
    title: 'Motivo',
    dataIndex: 'motivo',
    key: 'motivo',

  },
  {
    title: 'Fecha',
    dataIndex: 'createdAt',
    render: (text) => moment(text).format('YYYY/MM/DD'),
  },
  {
    title: 'Acciones',
    key: 'actions',
    render: (text, record) => (
      <span className="item-access-row-column" >
       <Space size="middle">
           <Button type="link" className="accion-text"   title="Editar" href={`/admin/reportes/edicion/${record._id}`} className="EditIcon EditIconDepto">Editar</Button>
           <Button type="link" className="accion-text"  name={record.nombreCompleto} id={record._id} onClick={showDeleteConfirm} >Eliminar</Button>
       </Space>
      </span>
    ),
  },
];


/**
 *
 *
 * @class Reportes
 * @extends {Component}
 */
class Reportes extends Component {

  constructor(props) {
    super(props)
    this.state = {

      list: [],
      loading: true,
      reportes: [],

      /**
     * Control de la paginación.
     * */
      currentPage: 1,
      itemCount: 1,
      pageCount: 1,
      perPage: 10,
      search: ''

    }
  }

  componentDidMount() {
    this.receivedData();
  }


  receivedData = (page, search = this.state.search) => {
    axios.get('/reportesPaginado', {
      headers: { Authorization: sessionStorage.getItem("token") },
      params: {
        page: page,
        limit: 10,
        search: search
      }
    })
      .then(res => {
        const dataList = res.data.data.itemsList
        const data = res.data.data;

        this.setState({
          loading: false,
          list: dataList,
          reportes: dataList,

          /**paginado */
          currentPage: data.paginator.currentPage,
          itemCount: data.paginator.itemCount,
          perPage: data.paginator.perPage,
          pageCount: data.paginator.pageCount,
        })

      })
      .catch(error => {
        console.log(error.response);
        Modal.error({
          title: "Ha ocurrido un error al cargar los datos"
        });
      });
  }

  searchData = (values) => {
    this.receivedData(1, values);
  }

  handlePageClick = page => {
    this.receivedData(page);
  };

  render() {
    return (
      <Layout className="dashboard-section-layout">
        <PageHeader className="dashboard-section-header nombreVista" title="Reportes">
          <Row type="flex" justify="space-between">
            <Col xs={18} lg={10} >
              <Search
                placeholder="Buscar reporte"
                enterButton="Buscar"
                size="large"
                className="input-search input-filtro"
                style={{ width: "100%", borderRadius: '10px' }}
                onSearch={this.searchData}
                initialValues={{ remember: false }}
              />
            </Col>
            <Col xs={1} lg={9}  >
              <Link to='/admin/reportes/creacion'> <FaPlus className="btnAddPlus" title="Nuevo registro" /></Link>
            </Col>
          </Row>
        </PageHeader>
        <Content className="dashboard-section-content">
          <div className="divForm">
            <Table columns={columns}
              dataSource={this.state.reportes}
              loading={this.state.loading}
              pagination={{
                onChange: this.handlePageClick,
                total: this.state.itemCount,
                current: this.state.currentPage,
                className: "pagination-table"
              }}
              className="blackTheme" />

          </div>
        </Content>
      </Layout>
    );
  }
}

export default Reportes;
