import React, { Component } from 'react';
import { Layout, Calendar, Badge, Modal, Row, Col, Radio, Spin, PageHeader, Buttonm, message } from 'antd';
import { FaPlus, FaDotCircle } from 'react-icons/fa';
import { IconPlus } from './../../Widgets/Iconos'

import ModalNoAutorizada from '../Modales/ModalNoAutorizada'
import ModalAutorizada from '../Modales/ModalAutorizada'
//css
import { AutoSizer } from 'react-virtualized';

import '../../global/css/Calendario.css';

const moment = require('moment');
const { Content } = Layout;
const axios = require('axios');


/**
 *
 *
 * @export
 * @class Calendario
 * @extends {Component}
 */
export default class Calendario extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            spinning: false,
            mode: 'month',
            valor: '',
            data: [],
            list: [],
            visible: true,
            admisiones: [],
            dataAdmisiones: [],
            dataAdmCasas: [],

            verModalNoAutorizada: false,
            verModalAutorizada: false,
            admision_id: "",
            filtroEstatus: ""
        }
    }


    /**
    *
    * @memberof Calendario
    *
    */
    componentDidMount() {
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
        this.getAdmisiones();
        this.getDataAdmisiones();
    }


    /**
     * @memberof Calendario
     * @method getAdmisiones
     *  @description retorna las admisiones aceptada por dia del mes seleccionado
     */
    getAdmisiones = (mes, año) => {
        this.setState({ spinning: true })
        axios.get('calendar', {
            params: {
                mes,
                año
            }
        }).then(response => {
            this.setState({
                admisiones: response.data.data,
            })
        }).catch(error => {
            message.error('Error al cargar los datos')
        }).finally(() => {
            this.setState({ spinning: false })
        })
    }



     /**
     * @memberof Calendario
     * @method getDataAdmisiones
     *  @description retorna la lista de admisiones con estatus aprobado o denegado, también el arreglo de las casas y cuantas veces tuvieron actividad
     */
    getDataAdmisiones = async () => {
        this.setState({ spinning: true });
        axios.get('/admisiones/list', {
            params: { filtroEstatus: this.state.filtroEstatus }
        })
            .then(response => {

                this.setState({
                    dataAdmisiones: response.data.data,
                    dataAdmCasas: response.data.dataCasas,
                    spinning: false, 
                })
            })
            .catch(error => {
                message.error('Error al cargar los datos')
            }).finally(() => {
                this.setState({ spinning: false })
            })
    }

    
    /**
    * @memberof Calendario
    * @method getAdmisiones
    *  @description rendiriza datos en el calendario
    */
    dateCellRender = (value) => {

        const { dataAdmisiones, dataAdmCasas } = this.state;
        let _cellDayValue = value.format('YYYY-MM-DD').toString();

        var listFecha = [];

        for (let index = 0; index < dataAdmisiones.length; index++) {
            var ind = 0, casaNumReg = 1;
            const admision = dataAdmisiones[index];
            let fecha = moment(admision.createdAt).format("YYYY-MM-DD").toString();


            if (_cellDayValue === fecha && admision.status !== 1) {

                var tipo = '', color = '', estatus = '';
                // 0 -> Rechazada
                // 1 -> Nueva
                // 2 -> Aceptada 
                if (admision.status == 0) {
                    color = '#d60420';
                    tipo = 'Denegada';
                    estatus = "Rechazada";
                }
                if (admision.status == 1) {
                    color = '#FDCA40';
                    tipo = 'Waiting';
                    estatus = "Pendiente";
                }
                if (admision.status == 2) {
                    color = '#1890ff';
                    tipo = 'Success';
                    estatus = "Aceptada";

                }
                if (admision.status == 3) {
                    color = '#ff4d4f';
                    tipo = 'Error';
                }


                if (admision.casa_id !== undefined) {
                    var admCasaId = admision.casa_id._id;
                    for (let index = 0; index < dataAdmCasas.length; index++) {
                        const casa = dataAdmCasas[index];
                        if (admCasaId === casa._id) {
                            casaNumReg = casa.count;
                            break;
                        }
                    }
                }

                ind = ind + 1;
                listFecha.push({
                    ind: ind,
                    _id: admision._id,
                    motivo: admision.motivo,
                    fechaVisita: fecha,
                    color: color,
                    nombre: (admision.nombre === undefined) ? "N/A" : admision.nombre,
                    datos: admision.datos,
                    descripcion: admision.descripcion,
                    type: (admision.tipo == 1) ? "Vehiculo" : "Peatón",
                    estatus: estatus,
                    status: tipo,
                    idEstatus: admision.status,
                    casa_nombre: (admision.casa_id !== undefined) ? admision.casa_id.nombre : "N/A",
                    casa_numReg: casaNumReg
                });
            }
        }

        return (
            <Row style={{  overflow: "auto",  width: "100%",  padding: "0px",  margin: "0px", }}>
                <Col span={24}>
                    <ul className="events">
                        {listFecha.map((item, index) => (
                            <a onClick={() => {
                                this.abrirModal(item._id, item.idEstatus)
                            }}>
                                <Col span={24} style={{ marginBottom: "12px" }}>
                                    <Badge
                                        id={item._id}
                                        status={item.status}
                                        color={item.color}
                                        text={item.casa_nombre + (item.casa_nombre !== "N/A" ? (" : " + item.casa_numReg) : "")}
                                    >
                                    </Badge>
                                </Col>
                            </a>
                        ))}
                    </ul>
                </Col>
            </Row>
        );

    }


    /**
    * @memberof Calendario
    * @method getAdmisiones
    *  @description rendiriza datos en el calendario
    */
    onChange = (value) => {
        let mes = moment(value).month()
        let año = moment(value).year()
        this.getAdmisiones(mes, año);
        this.getDataAdmisiones();
    }

    abrirModal = async (id, estatus) => {

        this.setState({
            admision_id: id,
            verModalAutorizada: (estatus === 2),
            verModalNoAutorizada: (estatus === 0)
        });
    }

    UpdateFiltroEstatus = (value) => {
        var estatus = (value === undefined) ? "" : value.target.value;

        this.setState({ filtroEstatus: estatus })
        setTimeout(() => {
            this.getDataAdmisiones();
        }, 250);
    }

    render() {
        return (
            <Layout className="dashboard-section-layout calendario-layout">
                <PageHeader className="dashboard-section-header nombreVista" title="Calendario">
                    <Row type="flex" justify="space-between">
                        <Col xs={18} lg={12} >
                            <div className="contenedor-etiquetas">
                                <div className="etiqueta"> </div>
                                <Col align="left" style={{ width: 'auto' }}>
                                    <Radio.Group title="Filtro" defaultValue="Todos" size="large" className="radioCalFiltro"
                                        onChange={this.UpdateFiltroEstatus}
                                    >
                                        <Radio.Button value="" title="Todas" className="divNivel lvlTodas">
                                            <FaDotCircle /> Todas
                                        </Radio.Button>

                                        <Radio.Button value={2} title="Aprobadas" className="divNivel lvlAprobada">
                                            <FaDotCircle /> Admisiones Aprobadas
                                        </Radio.Button>

                                        <Radio.Button value={0} title="Denegadas" className="divNivel lvlDenegada">
                                            <FaDotCircle /> Admisiones Denegada
                                        </Radio.Button>

                                        {/* <Radio.Button value="Solicitadas" title="Solicitadas" className="divNivel lvlSolicitada">
                                                <FaDotCircle /> Admisiones Solicitadas
                                        </Radio.Button> */}
                                    </Radio.Group>
                                </Col>
                            </div>
                        </Col>
                        <Col xs={1} lg={9} >
                            <FaPlus className="btnAddPlus nView" />
                        </Col>
                    </Row>
                </PageHeader>
                <Content className="dashboard-section-content">
                    <div className="divForm">
                        <Spin spinning={this.state.spinning}>
                            <Calendar
                                fullscreen={true}
                                dateCellRender={this.dateCellRender}
                                onChange={this.onChange}
                                className="div-calendario darkTheme calendario-admisiones"
                            />
                        </Spin>
                    </div>
                </Content>


                <ModalAutorizada
                    asistencia={this.state.admision_id} //ID DE LA ADMISION
                    visible={this.state.verModalAutorizada}
                    hideModal={() => this.setState({ verModalAutorizada: false, verModalNoAutorizada: false })}
                />

                <ModalNoAutorizada
                    asistencia={this.state.admision_id} //ID DE LA ADMISION
                    visible={this.state.verModalNoAutorizada}
                    hideModal={() => this.setState({ verModalAutorizada: false, verModalNoAutorizada: false })}
                />


            </Layout>
        );
    }
}




