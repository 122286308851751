
import React from "react";

import socketio from "socket.io-client";
import axios from "axios";

export const socket = socketio.connect("https://aide.ws.parqueesmeralda.mx/");
// export const socket = socketio.connect('http://localhost:4015');
export const SocketContext = React.createContext(socket);

export default socket;