import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, message, Input, Button } from 'antd';



const axios = require('axios');


/**
 *
 *
 * @class ModalAsuntoForm
 * @extends {Component}
 */
export default class ModalAsuntoForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            asunto: [],
            asunto_id: null
        }
    }
    formRef = React.createRef();


    /**
     * @memberof ModalAsuntoForm
     */
    componentDidMount() {
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
    }


    /**
     * @memberof ModalAsuntoForm
     * @method updateAsunto  
     * @description: actualiza un asunto
     */
    updateAsunto = (values) => {
        axios.post('/asuntos/update', {
            id: this.props.asunto._id,
            nombre: values.nombre
        }).then(res => {
            message.success('¡Asunto actualizado!');
            this.props.close()            
        }).catch(error => {
            message.error('Error al actualizar el asunto');
        })
    }


     /**
     * @memberof ModalAsuntoForm
     * @method addAsunto
     * @description: añade un asunto a la bd
     */
    addAsunto = (values) => {
        axios.post('/asuntos/add', {
            nombre: values.nombre
        }).then(res => {
            message.success('¡Asunto agregado!');
            this.props.close()
        }).catch(error => {
            message.error('Error al agregar el asunto');
        })
    }


    /**
     * @memberof ModalAsuntoForm
     * @method addAsunto
     * @description: se ejecuta al dar submit al formlario
     */
    onFinish = (values) => {
        if(this.props.asunto !== undefined){
            console.log('edit')
            this.updateAsunto(values)
        }else{
            console.log('new')
            this.addAsunto(values)
        }
    }

    render() {

        return (
            <Form onFinish={this.onFinish} className="modal-asunto" ref={this.formRef} initialValues={this.props.asunto}>
                <Row>
                    <Col span={24}>
                        <Form.Item 
                            className="form-item-content"
                            name="nombre" 
                            rules={[{ required: true, message: 'Por favor, ingrese el asunto' }]} >
                            <Input placeholder="Asunto" className="input-box" />
                        </Form.Item>
                    </Col>
                </Row>   
                <Row>
                    <Col span={24} className="center">
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="btn-green">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}
