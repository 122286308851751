import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import { Form, Input, Button, Col, Row, Modal,message } from 'antd';
import { RiLockPasswordLine } from "react-icons/ri";
import {AiOutlineUser}from "react-icons/ai";
import '../global/css/Login.css';


const axios = require('axios').default;


/**
 *
 *
 * @export
 * @class Login
 * @extends {Component}
 */
export default class Login extends Component {

    state = {
        redirect: false
    };


    /**
     *
     *
     * @memberof Login
     * @method onFinish
     * @description Envia los datos al WS
     */
    onFinish = async values => {
        axios
            .post("/login", {
                email: values.email,
                password: values.password
            })
            .then(response => {
                console.log("sesion iniciada", response);

                axios.defaults.headers.post["Authorization"] = response.data.token;
                sessionStorage.setItem('token', response.data.token);

                sessionStorage.setItem('userId', (response.data.user._id));
                sessionStorage.setItem('userName', (response.data.user.nombre));
                sessionStorage.setItem('userType', (response.data.user.rol));

                message.success("Se inicio sesión correctamente.")

                this.setState({ redirect: true });

            })
            .catch(error => {
                console.log(error)
                Modal.warning({
                    title: "No es posible iniciar sesión",
                    content: ""
                });
            });
    };


    /**
     * @memberof Login
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/admin/dashboard" />;
        }
    };



    render() {
        return (
            <div className="Login">
                {this.renderRedirect()}
                <div >
                    <Form onFinish={this.onFinish} className="login-form">
                        <img src={"/Logo-H.png"} style={{ width: '80%',padding:'30px 0px' }} />
                        <Row gutter={[16, 16]} className="pd-1">
                            <Col xs={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="email"
                                    label="Usuario"
                                    rules={[{ required: true, message: "Por favor ingrese su correo electrónico" }]}
                                >
                                    <Input prefix={<AiOutlineUser  />}  />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="password"
                                    label="Contraseña"
                                    rules={[{ required: true, message: "Por favor ingrese su contraseña" }]}
                                >
                                    <Input prefix={<RiLockPasswordLine />} type="password"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24}>
                                <Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-submit-btn btn-green">Iniciar sesión</Button>
                                </Form.Item>
                                <Link className="link" to="/password/forgot">¿Olvidaste tu contraseña?</Link>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
    }


}
