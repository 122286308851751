import React, { Component } from "react"
import { Layout, Input, Row, Col, Modal, Button, Form } from 'antd';
import { Redirect } from "react-router-dom";
import { Icon } from '@ant-design/compatible';

const axios = require('axios').default;


/**
 *
 *
 * @class PasswordForgot
 * @extends {Component}
 */
class PasswordForgot extends Component {

    constructor(props) {
        super(props)
        this.state = {
            return: false
        }
    }


    /**
 * @memberof PasswordForgot
 *
 * @method handleSubmit
 * @description  Envia los datos del formulario al Servidor
 *
 * @param values (array)
 * Contiene los campos del formulario para registrar al producto
 *
 * @returns response (array)
 **/
    handleSubmit = value => {
        console.log(value);
        axios.put("/password/recovery", { email: value.email })
            .then(res => {
                console.log(res);
                Modal.success({
                    title: 'Correo',
                    content: 'El correo ha sido enviado. Revisa tu bandeja de entrada!',
                    onOk: e => { e(); this.setState({ return: true }) }
                });
            })
            .catch(res => {
                console.log(res.response);
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar.Verifica que sea el mismo con el que te registraste.'
                });
            });
    }

    /**
 * @memberof PasswordForgot
 *
 * @method renderRedirect
 * @description  Activa el redireccionamiento si el formulario se envio con exito
 *
 **/
    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/login" />;
        }
    };


    render() {
        return (
            <div className="Login">
                {this.renderRedirect()}
                <Form onFinish={this.handleSubmit} Layout={"vertical"}  >
                    <Row className="login-form" align="center">
                        <h2 className="pd-1 Login-Title">Restauración de Contraseña</h2>
                        <Col xs={24} md={24} lg={24}  >
                            <Form.Item name="email" rules={[{ required: true, message: 'Por favor ingresa tu correo electrónico' }]}>
                                <Input type="email" prefix={<Icon type="email" />} placeholder="Correo"></Input>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-submit-btn" >Enviar Link</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </div>

        )
    }
}

export default PasswordForgot
