//Dependencias
import React, { Component } from 'react';
import { Layout, Row, Col, Pagination } from 'antd';
import { Notificaciones, SolicitudesAccesos, UltimosAccesos } from './widgets'
import {
    Chart_AsuntoVisitas, Chart_Barras, Chart_VisitantesActuales, Chart_PerfilVisitantes, Chart_AreasAcceso,
    Chart_VisitasInmuebles, Chart_HorarioVisitas, Chart_ClasificacionAccesos
} from './graficas'


//assets
import '../../global/css/Dashboard.css';
const { Content } = Layout;


const moment = require('moment');
const axios = require('axios');




/**
 *
 *
 * @class Dashboard
 * @extends {Component}
 */
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            imagen: [],

            pageRequests: 1,
            quantityRequests: 0,

            dataRequests: [],
            totalRequests: 0,

            filterRequests: [],
            loadingRequests: false,

            pageAccess: 1,
            quantityAccess: 0,

            dataAccess: [],
            totalAccess: 0,

            filterAccess: [],
            loadingAccess: false,

            dataNotificaciones: [],


            /**
             * Control de la paginación.
             * */
            limit: 10,
            currentPage: 1,
            itemCount: 1,
            pageCount: 1,
            search: ''
        }
    }


    componentDidMount = () => {
        this.getNotifications(1);
    }


    getNotifications = (page) => {
        axios.get('/dashboard/notificaciones', {
            headers: { Authorization: sessionStorage.getItem("token") },
            params: {
                page: page,
                limit: this.state.limit,
            }
        })
            .then(res => {

                const dataList = res.data.data.itemsList;
                const data = res.data.data.paginator;

                this.setState({
                    loading: false,
                    dataNotificaciones: dataList,

                    /**paginado */
                    itemCount: data.itemCount,
                    perPage: data.perPage,
                    pageCount: data.pageCount,
                    currentPage: data.currentPage,
                })
            })
            .catch(error => {
                console.log(error)
            });
    }


    handlePageClick = page => {

        this.getNotifications(page);
    };

    render() {

        return (
            <Layout className="dashboard-section-layout">
                <Content className="cnt-dashboard" >
                    <Row className="row-cnt-dashboard" >
                        <Col span={24} xs={24} xl={16}>
                            <Col span={24} xs={24} >
                                <Row gutter={[16, 16]} >
                                    <Col span={12} xs={24} md={12}>
                                        <div className="div-contenedor">
                                            <div className="div_titulo"> Asunto de Visitas</div>
                                            <div className="div_contenido">
                                                <Chart_AsuntoVisitas />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12} xs={24} md={12}>
                                        <div className="div-contenedor">
                                            <div className="div_titulo"> Visitas por Inmuebles</div>
                                            <div className="div_contenido">
                                                <Chart_VisitasInmuebles />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12} xs={24} md={12}>
                                        <div className="div-contenedor">
                                            <div className="div_titulo"> Visitantes Actuales</div>
                                            <div className="div_contenido">
                                                <Chart_HorarioVisitas />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12} xs={24} md={12}>
                                        <div className="div-contenedor">
                                            <div className="div_titulo"> Clasificación Accesos</div>
                                            <div className="div_contenido" style={{ textAlign: "center" }}>
                                                <Chart_ClasificacionAccesos />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col span={24} xs={24} md={24}>
                                        <UltimosAccesos />
                                    </Col>
                                </Row>
                            </Col>

                        </Col>

                        <Col span={24} xs={24} xl={8} >
                            <Row style={{ padding: "0px 15px" }}>
                                <Col span={24} >
                                    <div className="div-contenedor">
                                        <div className="div_titulo"> Notificaciones</div>
                                        <div className="div_contenido" style={{ textAlign: "center" }}>
                                            <Notificaciones data={this.state.dataNotificaciones} />
                                        </div>

                                        <Row className="pd-1 " justify="end">
                                            <Pagination
                                                total={this.state.itemCount}
                                                current={this.state.currentPage}
                                                className="pagination-table"
                                                perPage={1}
                                                onChange={this.handlePageClick}
                                            />
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}


export default Dashboard;