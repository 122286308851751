import React from 'react'
import { Link } from 'react-router-dom'
const queryString = require('query-string');

const defineFunction = (key, value = "", func = () => {} ) => {
    switch (key) {
        case 'roles':
            return {to: '/admin/usuarios/tipos/ver/' + value};

        case 'usuarios':
            return {to: '/admin/usuarios/ver/' + value};
        case 'reportes':
            return {to: '/admin/reportes/edicion/' + value}

        case 'nueva_solicitud':
            return {onClick: () => func(queryString.parse(value))}
    }
}

const createUrl = (string, func = () => {}) => {
    let link = string.replace('}', '').replace('{', '').split('|')
    return <Link {...defineFunction( link[1], link[2], func)} >{link[0]}</Link>
}

//Partimos el STRING en texto normal y texto entre corchest. A los corechetes les crearemos un URL
const reformat = (string, func = () => {}) => string.split(/(\{.*?\})/).map(string => string.match(/{.*}/) ? createUrl(string, func) : string)

export { reformat, createUrl }