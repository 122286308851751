import React, { Component } from 'react';
import { Modal, Row, Col, Button, Typography, Form, Input } from 'antd';
//componentes
import { IconError } from '../../Widgets/Iconos'
//css
import '../../global/css/modalesTEMP.css'

const { Title, Text } = Typography;
const moment = require('moment');
const axios = require('axios');

class ModalNoAutorizada extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {}
		}
	}


	componentDidMount() {
		this.getAdmision()
	}


	hideModal = () => {
		this.props.hideModal();
	}

	/**
	 *
	 *
	 * @memberof Header
	 * @description Recibimos el ID de admisión.
	 */
	getAdmision = (id = this.props.asistencia) => {
		this.setState({ loading: true })
		axios.get('/admision/autorizada', {
			params: {
				id
			}
		})
			.then(({ data }) => this.setState({ form: data.data }))
			.catch(error => { })
			.finally(() => this.setState({ loading: false }))
	}


	render() {


		const { form = {} } = this.state

		return (
			<div>
				<Row>
					<Col span={24} className='modal-header'>
						<img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal" />
					</Col>
					<Col span={24} className="center mt-1">
						<Title level={3} className="modal-title">{this.props.tipo} No Autorizada</Title>
					</Col>
				</Row>

				<Row align="center">
					<Col span={24} className='modal-header mb-1'>
						<IconError />
					</Col>
					<Col span={15} className="center">
						<Title level={4} className="modal-sub-title">
							{form?.tipo === 0 ? 'Esta persona tiene el acceso bloqueado'
								: 'Este vehiculo tiene el acceso bloqueado'}
						</Title>
					</Col>
				</Row>

				<Form className="modal-form" >
					{form.tipo === 0 ? <div>
						<Row align="center ">
							<Col span={11} className="">
								<Form.Item name="nombre" label="Nombre Completo">
									<Text className="modal-text">{(form?.visitante_id?.nombre)}</Text>
								</Form.Item>
							</Col>
							<Col span={11} offset={1} className="">
								<Form.Item name="hora_entrada" label="Fecha y Hora">
									<Text className="modal-text">{moment(form.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text>
								</Form.Item>
							</Col>
						</Row>
						<Row align="center">
							<Col span={11}>
								<Form.Item name="casa" label="Casa" >
									<Text className="modal-text">{form?.casa_id?.nombre}</Text>
								</Form.Item>
							</Col>
							<Col span={11} offset={1}>
								<Form.Item name="asunto" label="Asunto" >
									<Text className="modal-text">{form.asunto_id?.nombre}</Text>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={11} style={{ marginLeft: '12px' }}>
								<Form.Item name="foto" label="Foto" >
									<img
									className="modal-text"
										width={100}
										src={axios.defaults.baseURL + 'upload/' +form?.visitante_id?.comprobantes}
										alt={'Imagen Visitante'}
									/>
								</Form.Item>
							</Col>
						</Row>
					</div>
						:
						<div>
							<Row align="center ">
								<Col span={11} className="">
									<Form.Item name="casa" label="Casa">
										<Text className="modal-text">{form.casa_id?.nombre}</Text>
									</Form.Item>
								</Col>
								<Col span={11} offset={1} className="">
									<Form.Item name="hora_entrada" label="Fecha y Hora">
										<Text className="modal-text">{moment(form.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text>
									</Form.Item>
								</Col>
							</Row>
							<Row align="center">
								<Col span={11}>
									<Form.Item name="descripcion" label="Vehiculo">
										<Text className="modal-text">{form?.descripcion}</Text>
									</Form.Item>
								</Col>
								<Col span={11} offset={1}>
									<Form.Item name="datos" label="Placas">
										<Text className="modal-text">{form?.datos}</Text>
									</Form.Item>
								</Col>
							</Row>
						</div>}

				</Form>

				<Row className="center">
					<Button type="primary" htmlType="submit" className="btn-green" onClick={this.hideModal}>
						Cerrar
			        </Button>
				</Row>
			</div>

		);
	}
}



export default function (props) {

	return <Modal
		className="modal-aide"
		visible={props.visible}
		closable={false}
		footer={false}
		destroyOnClose={true}
		onCancel={props.hideModal}
		width={650}
	>
		<ModalNoAutorizada {...props} />
	</Modal>

}