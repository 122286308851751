//Dependencias
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import { AiOutlineBell, AiOutlineDown } from 'react-icons/ai'
import { Badge, Comment, Dropdown, Menu, Spin, Tooltip, Col, Tag, notification, Modal, Button } from 'antd'
//assets
import logo from './images/aide-logo-large.png';
import CustomAvatar from '../Widgets/Avatar/Avatar'
import './css/Header.css';

import { reformat } from '../LinkUrlGenerate';
import { SocketContext } from '../../hooks/Socket';


import ModalSolicitudDetectada from '../Admin/Modales/ModalSolicitudDetectada';


import ModalAutorizar from '../Admin/Modales/ModalAutorizar';
import ModalNoAutorizada from '../Admin/Modales/ModalNoAutorizada';
import ModalAutorizada from '../Admin/Modales/ModalAutorizada';



import axios from 'axios';


const moment = require('moment');
moment.locale('es');

/**
 *
 *
 * @class Header
 * @extends {Component}
 * @description Header del dashboard
 */
class Header extends Component {

    state = {
        title: 'AIDE',
        itemsList: [],
        currentPage: 0,
        itemCount: 0,
        loading: false,
        userName: "",
        userType: "",
        logout: false,
        notificationsCounter: 0,


        admision_id: null,
        cantidad: 0,
        hora_entrada: null,
        ModalSolicitudDetectada: false,
        ModalAutorizada: false,
        ModalNoAutorizada: false
    }

    static contextType = SocketContext


    /**
     *Creates an instance of Header.
     * @param {*} props
     * @memberof Header
     */
    constructor(props) {
        super(props);
    }


    /**
     *
     *
     * @static
     * @memberof Header
     */
    static propTypes = {
        title: PropTypes.string.isRequired
    };


    /**
     *
     *
     * @memberof Header
     */
    componentDidMount() {
        this.setState({ ModalNoRegistrada: true })

        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
        let userName = sessionStorage.getItem('userName')
        if (userName != undefined) {
            this.setState({ userName: userName });
        }

        this.context.emit('initialize', sessionStorage.getItem('token'))
        this.context.on('notifications', (notifications) => this.setState(state => {
            if (Array.isArray(notifications?.itemsList)) {
                state.itemsList = [...notifications.itemsList, ...state.itemsList]
                state.currentPage = notifications.paginator.currentPage
                state.itemCount = notifications.paginator.itemCount
                state.loading = false
                return state;
            }
            return state;
        }))

        this.context.on('notifications-counter', (notifications) => this.setState({ notificationsCounter: notifications }))


        this.context.on('/solicitud/notificacion', (notificacion) => {
            
            notification.warning({
                duration: 15,
                message: <div>
                    Se ha recibido una solicitud de acceso en <strong>{(notificacion?.admision?.tipo == 1) ? "Entrada Vehicular" : "Entrada Peatonal"}</strong>
                </div>,
                description:
                    'Hemos recibido una notificación de acceso. Haga click aquí para continuar.',
                onClick: () => this.openAdmision(notificacion?.admision),
                placement: "bottomRight"
            });
        })

        this.context.emit('notifications', { page: 1 })
    }


    /**
     *
     *
     * @memberof Header
     */
    updateNotifications = () => {
        const page = this.state.currentPage + 1;
        this.setState({
            loading: true
        });
        this.context.emit('notifications', {
            page: page
        });
    };


    handleScroll = (e) => (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) ? this.updateNotifications() : null

    onCloseDropdown = async (e) => (!e) ? this.context.emit('notifications-read-it', this.state.itemsList.map(item => item._id)) : null


    /**
     *
     *
     * @memberof Header
     * @description Manda la peticion para la verificacion de los datos
     * @param admision ObjectId
     */
    openAdmision = (admision, id) => {
        
        this.props.setLoading(true);


        const not_admision = admision;
        this.state.admision = not_admision;
        
        this.state.tipo = admision.tipo;

        axios.get('/admision', {
            params: {
                admision,
                _id: id
            }
        })
            .then((rec) => {

                var data = (rec.data.data._doc !== undefined) ? rec.data.data._doc : rec.data
                var cantidad = (rec.data.data._doc !== undefined) ? rec.data.data.cantidad : rec.data.cantidad
 

                if (rec.data.data._doc !== undefined) {
                    this.state.admision_id = id;
                }

                switch (data.status) {
                    case 0: 
                        this.state.admision_id = data._id;
                        break;
                    case 1:
                        this.state.cantidad = cantidad;
                        this.state.ModalSolicitudDetectada = true;
                        break;
                    case 2:
                        this.state.admision_id = data._id;
                        this.state.ModalAutorizar = true;

                        break;
                    default:
                        this.state.admision_id = null;
                        this.state.cantidad = cantidad;
                        this.state.ModalSolicitudDetectada = true;
                        break;
                }
            })
            .catch(error => {
                console.log('error', error);
            })
            .finally(() => this.props?.setLoading(false))

    }

    /**
    *
    *
    * @memberof Header
    * @description Asigna al state los valores de  la admision y la hora
    * @param id ObjectId
    * @param hora_entrada Moment
    */
    setAcceso = (id, hora_entrada) => { 
        this.setState({ admision_id: id, hora_entrada: hora_entrada })
    }

    /**
     *
     *
     * @memberof Header
     * @description Metodo que manda la peticion para generar la asistencia de la admision
     * @param acceso Boolean 
     */
    addAcceso = (acceso) => {

        const { admision_id, hora_entrada } = this.state
        axios.post('accesos/add', {
            admision_id: admision_id,
            hora_entrada: hora_entrada,
            entrada: acceso
        })
            .then(res => {
                this.state.admision_id = admision_id;
                if (acceso == true)
                    this.Autorizar(admision_id);
                else
                    this.NoAutorizar(admision_id)
            })
            .catch((error) => {
                console.log('error', error);
            });


    }

    /**
     *
     *
     * @memberof Header
     * @description Metodo que realiza la pregunta de acceso para las visitas
     * @param notificar Bolean
     */
    notificarResidente = (notificar) => {
        this.setState({ ModalSolicitudDetectada: false });
        if (notificar == true) {
            let esto = this;
            return (
                Modal.confirm({
                    title: 'Notificar al Residente',
                    okText: 'Acepto',
                    cancelText: 'No Acepto',
                    content: (<div><p>Es necesario que el residente apruebe la solicitud de acceso para aceptar al visitante</p></div>),
                    onOk() {
                        esto.addAcceso(true);
                    },
                    onCancel() {
                        esto.NoAutorizar()
                    },
                })
            )
        }

    }

    /**
    *
    *
    * @memberof Header
    * @description Metodo que abre el modal de Autorizada
    * @param id ObjectId
    */
    Autorizar = (id) => {
        this.state.admision_id = id;
        this.setState({ ModalAutorizada: true });

    }

    /**
     *
     *
     * @memberof Header
     * @description Metodo que abre el modal de NoAutorizada
     * @param id ObjectId
     */
    NoAutorizar = (id) => {
        this.state.admision_id = id;
        this.setState({ ModalNoAutorizada: true })
    }


    /**
     *
     *
     * @memberof Header
     * @description Metodo que elimina el sessinStorage para salir del dashboard
     */
    cerrarSesion = () => {
        sessionStorage.clear();
        setTimeout(() => {
            this.setState({ logout: true });
        }, 250);
    };


    /**
     *
     *
     * @memberof Header
     * @description Rediccionamiento al login
     */
    redirect = () => {
        if (this.state.logout == true) {
            return <Redirect to="/login" />
        }
    }


    /**
     *
     *
     * @memberof Header
     * @description Lista de notificaciones 
     */
    renderNotifications = () => {
        const { itemsList, itemsCount, loading, notificationsCounter } = this.state;
        const { updateNotifications, handleScroll, onCloseDropdown } = this;

        
        return <Dropdown
            onVisibleChange={onCloseDropdown}
            overlay={
                <div onScroll={handleScroll}>
                    {
                        (itemsList.length < 1) ? <Menu
                        className="notifications-list"
                            theme="dark"
                        >

                            <Menu.Item>
                                <a>
                                    Sin notificaciones
                                </a>
                            </Menu.Item>
                        </Menu> : <Menu className="notifications-list"
                        theme="dark"

                        >{
                            itemsList.map((notif, index) => {
                                let accion = "Nuevo evento"
                                if (notif.evento != undefined && notif.evento.accion != undefined) {
                                    accion = (notif.evento.accion != undefined) ? (notif.evento?.accion.includes("{")) ? notif.evento?.accion.split('con')[0] : notif.evento?.accion : "Evento";
                                }
                                return <Menu.Item className="notification-element" key={index}>
                                    <Comment
                                        author={notif.usuario_origen?.nombre}
                                        content={<p style={{ color: 'white' }}>{reformat(accion, (id_admision) => this.openAdmision(id_admision, notif.objeto_id))}</p>}
                                        datetime={
                                            <Tooltip
                                                title={moment(new Date(notif.createdAt.toString()), 'DD-MM-YYYU HH:mm:ss')}>
                                                <span>{moment(new Date(notif.createdAt.toString()), 'DD-MM-YYYU HH:mm:ss').fromNow()}</span>
                                            </Tooltip>
                                        }
                                    />
                                    {(notif.leido == false) ? <Tag color="green" className="reciente">Nueva</Tag> : null}
                                </Menu.Item>
                            })
                        }
                            {(loading) ? <Spin spinning={true} /> : null}
                        </Menu>
                    }
                </div>
            } trigger={['click']}
            className="notificaciones">
                
            <Badge count={notificationsCounter} overflowCount={99}>
                <AiOutlineBell className="IconoBell" />
            </Badge>
        </Dropdown>
    }

    /**
     * ---------------------METODOS DE PRUEBAS-------------------------
     * @returns 
     * @descripcion Manda una peticion para generar datos random de residente o visita
     * 
     */
    seederVisitanteNuevo = async () => {
        await axios.post('testing/visitante/nuevo',
            {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then(res => {
                const data = res.data.data;

                
                axios.post('admision/registrar', data,
                    {
                        headers: { Authorization: sessionStorage.getItem("token") },
                    })
                    .then(res => {
                        
                        let is_residente = res.data.residente;
                        if (is_residente == false) {
                            let admision = res.data.data;
                            this.setAcceso(admision._id, data.hora_entrada);
                            
                            this.notificarResidente(true);
                        }
                        else {
                            let asistencia = res.data.data;
                            this.setAcceso(asistencia.admision_id, data.hora_entrada);
                            this.addAcceso(true);
                        }
                    })
            })

    }

    /**
     *
     *
     * @memberof Header
     * @description Manda una peticion al servidor para traer una admision random
     */
    sedderVisitanteExistente = async () => {
        await axios.post('testing/visitante/existente',
            {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then(res => {
                const data = res.data.data;

                
                axios.post('admision/registrar', data,
                    {
                        headers: { Authorization: sessionStorage.getItem("token") },
                    })
                    .then(res => {
                        let is_residente = res.data.residente;
                        if (is_residente == false) {
                            let admision = res.data.data;
                            this.setAcceso(admision._id, data.hora_entrada);
                            this.notificarResidente(true);
                        }
                        else {
                            let asistencia = res.data.data;
                            this.setAcceso(asistencia.admision_id, data.hora_entrada);
                            
                            this.addAcceso(true);
                        }

                    })
            })


    }


    render() {
        const { userName, userType } = this.state;
        let userAvatar = <div><CustomAvatar name={userName} /><span style={{ marginLeft: '10px' }}></span></div>

        return (
            <div className="header">
                {this.redirect()}
                <Col span={4} className="header-logo">
                    <img className="logo" src={logo} alt="logo" />
                </Col>
                <Col span={2} style={{ paddingTop: '15px' }} >
                    <Button className="addVisitante" block title="Nuevo Visitante" onClick={this.seederVisitanteNuevo} >Nueva Visita </Button>
                    <Button className="addVisitante" block title="Visitante Existente" onClick={this.sedderVisitanteExistente} > Visita Registrada </Button>
                </Col>
                <Col span={18} className="header-content" style={{ paddingTop: "20px" }}>
                    <div className="header-options">
                        {this.renderNotifications()}
                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item key="0" onClick={() => this.cerrarSesion()}>
                                    Cerrar Sesión
                                </Menu.Item>
                            </Menu>
                        } trigger={['click']}>
                            <div className="ml-1">
                                <a>
                                    {userAvatar}
                                </a>
                                <a className="ant-dropdown-link header-user-drop" onClick={e => e.preventDefault()}
                                    style={{ marginLeft: '10px' }}>
                                    {userName} <AiOutlineDown />
                                </a>
                            </div>
                        </Dropdown>
                    </div>
                </Col>


                <ModalSolicitudDetectada
                    visible={this.state.ModalSolicitudDetectada}
                    admision_id={this.state.admision_id}
                    admision={this.state.admision}
                    cantidad={this.state.cantidad}
                    setAcceso={this.setAcceso}
                    hideModal={this.notificarResidente}
                    addAcceso={this.addAcceso}
                    NoAutorizar={this.NoAutorizar}
                />

                <ModalAutorizar
                    asistencia={this.state.admision_id}
                    visible={this.state.ModalAutorizar}
                    hideModal={() => this.setState({ ModalAutorizar: false })}
                    onFinish={(success) => {
                        
                        if (success == true) {
                            this.setState({ ModalAutorizada: true })
                        } else {
                            this.setState({ ModalNoAutorizada: true })
                        }
                    }}
                />


                <ModalAutorizada
                    asistencia={this.state.admision_id}
                    visible={this.state.ModalAutorizada}
                    hideModal={() => this.setState({ ModalAutorizada: false, ModalAutorizar: false, })}

                />
                <ModalNoAutorizada
                    asistencia={this.state.admision_id}
                    visible={this.state.ModalNoAutorizada}
                    hideModal={() => this.setState({ ModalNoAutorizada: false, ModalAutorizar: false })}
                />



            </div>
        );
    }
}

export default Header;
