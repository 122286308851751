//Dependencias
import React, { Component } from 'react';
import { Layout, Modal, PageHeader, Table, List, Row, Col, Pagination, Typography } from 'antd';


import { reformat } from '../../LinkUrlGenerate';


const moment = require('moment');
const axios = require('axios');
const { Content } = Layout;
const { Text } = Typography


/**
 *
 *
 * @class Historial
 * @extends {Component}
 * @description Bitacora de movimientos del sistema
 */
class Historial extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: true,
            list: [],

            /**
             * Control de la paginación.
             * */
            currentPage: 1,
            itemCount: 1,
            pageCount: 1,
            search: ''
        }
    }

    componentDidMount() {
        this.getHistorial();

    }


    getHistorial = (page, search = this.state.search) => {
        axios.get('/bitacora', {
            headers: { Authorization: sessionStorage.getItem("token") },
            params: {
                page: page,
                limit: 10,
                search: search
            }
        })
            .then(res => {
                const dataList = res.data.data.itemsList
                const data = res.data.data;

                this.setState({
                    loading: false,
                    list: dataList,

                    /**paginado */
                    itemCount: data.itemCount,
                    perPage: data.perPage,
                    pageCount: data.pageCount,
                    currentPage: data.currentPage,
                })
                console.log("ok", dataList);
            })
            .catch(error => {
                console.log(error.response);
                Modal.error({
                    title: "Ha ocurrido un error al cargar los datos"
                });
            });
    }

    searchData = (values) => {
        console.log("buscando ando", values);
        this.getHistorial(1, values);

    }

    handlePageClick = page => {
        this.getHistorial(page);
    };

    render() {
        const { list } = this.state;

        return (

            <Layout className="dashboard-section-layout">
                <Content className="dashboard-section-content">
                    <div className="divForm">
                        <Row justify="start" className="pd-1 row-enc-vista"  >
                            <Col xs={24}>
                                <p className="nombreVista">Historial</p>
                            </Col>
                        </Row>
                        <Row className="pd-1 " >
                            <List
                                className="list-item-access blackTheme"
                                dataSource={list}
                                loading={this.state.loading}

                                header={
                                    <Row className=" list-header"  >
                                        <Col span={4} className="item-access-row-column">
                                            <Text ellipsis className="list-header">Nombre</Text>
                                        </Col>
                                        <Col span={5} className="item-access-row-column">
                                            <Text ellipsis className="list-header">Email</Text>
                                        </Col>
                                        <Col span={3} className="item-access-row-column">
                                            <Text ellipsis className="list-header">Casa</Text>
                                        </Col>
                                        <Col span={2} className="item-access-row-column">
                                            <Text ellipsis className="list-header">Movimiento</Text>
                                        </Col>
                                        <Col span={6} className="item-access-row-column">
                                            <Text ellipsis className="list-header">Activo</Text>
                                        </Col>
                                        <Col span={2} className="item-access-row-column">
                                            <Text ellipsis className="list-header">Fecha</Text>
                                        </Col>

                                    </Row>
                                }
                                renderItem={item => {
                                    return <List.Item className="div-accesos-list ">
                                        <Row className="item-access-row item-db-row"  >
                                            <Col span={1} className="item-access-row-column">
                                                <Text ellipsis className="muesca"></Text>
                                            </Col>
                                            <Col span={4} className="item-access-row-column">
                                                <Text ellipsis> {item.nombre}</Text>
                                            </Col>
                                            <Col span={4} className="item-access-row-column">
                                                <Text ellipsis> {item.email}</Text>
                                            </Col>
                                            <Col span={3} className="item-access-row-column">
                                                <Text ellipsis> {item.casa_id?.nombre}</Text>
                                            </Col>
                                            <Col span={2} className="item-access-row-column">
                                                <Text ellipsis> {item.movimiento}</Text>
                                            </Col>
                                            <Col span={6} className="item-access-row-column">
                                                <Text ellipsis> {reformat(item.accion)}</Text>
                                            </Col>
                                            <Col span={2} className="item-access-row-column">
                                                <Text ellipsis> {moment(item.createAt).format('YYYY/MM/DD')}</Text>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                }}
                                pagination={false}
                            />
                        </Row>
                        <Row className="pd-1 " justify="end">
                            <Pagination
                                total={this.state.itemCount}
                                current={this.state.currentPage}
                                className="pagination-table"
                                onChange={this.handlePageClick}
                            />
                        </Row>
                    </div>
                </Content>
            </Layout>


        );
    }
}


export default Historial;
