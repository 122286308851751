//Dependencias
import React, { Component } from 'react';
import { Button, Card, Input, Row, Col, Typography, Layout, List, Modal, DatePicker, PageHeader, Select, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import FormNuevaAdmision from './Formularios/FormNuevaAdmision';
import Detalle from './Formularios/Detalle';
import { IconAccess, IconSearch, IconSelectArrow, IconPlus } from './../../Widgets/Iconos'
import ModalAutorizada from '../Modales/ModalAutorizada'
//css
import '../../global/css/Accesos.css';

const axios = require('axios');
const moment = require('moment');
const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;


/**
 * @class Accesos
 * @extends {Component}
 */
export default class Accesos extends Component {

    /**
     * @memberof Accesos
     * 
     *
     * @pageAccess 
     * @quantityAccess
     * @dataAccess
     * @totalAccess
     * @loading
     * @datefilter
     * 
     * @detalleVisible
     * @firmaVisible
     * @nuevaAdmisionVisible
     * @visitaAutorizadaVisible
     * 
     * @admision_id
     */
    state = {

        dataAccess: [
            {
                _id: 1,
                solicitud_id: {
                    descripcion: 'Solicitud Descripcion',
                    representantes_ids: [{
                        nombre: 'Representante Nombre'
                    }]
                },
                entrada: true,
                accesos: {
                    hora_entrada: new Date()
                }

            }
        ],

        loading: false,
        currentPage: 1,
        itemCount: 1,
        pageCount: 1,
        search: '',
        datefilter: null,
        casas: [],
        asuntos: [],

        // modal states
        detalleVisible: false,
        firmaVisible: false,
        nuevaAdmisionVisible: false,
        visitaAutorizadaVisible: false,

        admision_id: null,
    }



    /**
     * @method componentDidMount
     * @description Obtiene todos los accesos y solicitudes al cargar la vista 
     * */
    componentDidMount() {
        // this.getAccessRequests();
        this.getAllAccess();
        this.getCasas();
        this.getAsuntos();
    }

    /**
     * @method Accesos
     * @description Obtiene todos los accesos 
     * */
    getAllAccess = (page, search = this.state.search) => {
        axios.get('/admisiones/paginacion', {
            params: {
                page: page,
                limit: 10,
                search: search,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                tipo: this.state.tipo,
                casa_id: this.state.casa_id,
                asunto_id: this.state.asunto_id,
            }
        })
            .then(e => {
                console.log('accesos', e)

                this.setState({
                    dataAccess: e.data.data.itemsList,
                    itemCount: e.data.data.paginator.itemCount,
                    currentPage: e.data.data.paginator.currentPage,
                    pageCount: e.data.data.paginator.pageCount,
                })
            })
            .catch(e => console.log(e))
            .finally(f => {
                this.setState({ loading: false })
            })
    };

    /**
     * @method Accesos
     * @description Obtiene todos los accesos 
     * */
    getCasas = (page, search = this.state.search) => {
        axios.get('/casas', {

        })
            .then(e => {
                this.setState({
                    casas: e.data.data,

                })
            })
            .catch(e => console.log(e))
            .finally(f => {
                this.setState({ loading: false })
            })
    };

    /**
     * @method Accesos
     * @description Obtiene todos los accesos 
     * */
    getAsuntos = () => {
        axios.get('/asuntos', {
            params: {
                limit: 1000
            }
        })
            .then(e => {
                this.setState({
                    asuntos: e.data.data.itemsList,
                })
            })
            .catch(e => console.log(e))
    };


    /**
     * @method Accesos
     * @description
     * */
    updateChange = (value) => {
        const { pageAccess, quantityAccess } = this.state;
        this.getAllAccess(pageAccess, quantityAccess, value);
    }

    /**
     * @memberof Accesos
     * @description Asigna al state datefilter el valor a buscar
     */
    dateSearch = (value) => {
        const { pageAccess, quantityAccess } = this.state;
        this.setState({ datefilter: value });
        this.getAllAccess(pageAccess, quantityAccess, null, value);
    }

    /**
     * @memberof Accesos
     * @description Asigna al state search el valor  a buscar y manda la peticion al ws con la busqueda 
     */
    dataSearch = (value) => {
        this.setState({ search: value });
        this.getAllAccess(this.state.currentPage, value)
    }

    /**
     * @memberof Accesos
     * @description Abre el modal para crear un nuevo acceso
     */
    abrirModalCreacion = async () => {
        this.setState({
            nuevaAdmisionVisible: true,
            admision_id: null,
        });
    };


    /**
     * @memberof Accesos
     * @description Abre el modal de detalle del acceso
     */
    abrirModalDetalle = async (regId) => {
        console.log(regId)
        this.setState({
            detalleVisible: true,
            admision_id: regId,
        });
    };


    /**
     * @memberof Accesos
     * @description Metodo para cerrar modales
     * 
     */
    handleCancel = async (e) => {
        this.setState({
            detalleVisible: false,
            nuevaAdmisionVisible: false,
            visitaAutorizadaVisible: false,
            firmaVisible: false,
            admision_id: null,
        });

        this.getAllAccess(1);
    };

    /**
     * @memberof Accesos
     * @description fltra por busqueda
     * 
     */
    searchData = async (search) => {
        console.log(search)
        this.setState({
            search: search
        }, () => {
            this.getAllAccess(1)
        })
    };

    /**
     * @memberof Accesos
     * @description filtra por rango de fechas
     * 
     */
    onDateChange = async (dates) => {
        if (dates !== null) {
            this.setState({
                loading: true,
                start_date: moment(dates[0]).format('YYYY-MM-DD'),
                end_date: moment(dates[1]).format('YYYY-MM-DD')
            }, () => {
                this.getAllAccess(1)
            })
        } else {
            this.setState({
                loading: true,
                start_date: undefined,
                end_date: undefined
            }, () => {
                this.getAllAccess(1)
            })
        }
    };

    /**
     * @memberof Accesos
     * @description filtra por tipo de entrada
     * 
     */
    changeTipo = async (tipo) => {
        if (tipo !== null) {
            this.setState({
                loading: true,
                tipo: tipo
            }, () => {
                this.getAllAccess(1)
            })
        } else {
            this.setState({
                loading: true,
                tipo: undefined,
            }, () => {
                this.getAllAccess(1)
            })
        }
    };

    /**
    * @memberof Accesos
    * @description filtra por casa
    */
    changeCasa = async (casa_id) => {
        if (casa_id !== null) {
            this.setState({
                loading: true,
                casa_id: casa_id
            }, () => {
                this.getAllAccess(1)
            })
        } else {
            this.setState({
                loading: true,
                casa_id: undefined,
            }, () => {
                this.getAllAccess(1)
            })
        }
    };

    /**
    * @memberof Accesos
    * @description filtra por asunto
    */
    changeAsunto = async (asunto_id) => {
        if (asunto_id !== null) {
            this.setState({
                loading: true,
                asunto_id: asunto_id
            }, () => {
                this.getAllAccess(1)
            })
        } else {
            this.setState({
                loading: true,
                asunto_id: undefined,
            }, () => {
                this.getAllAccess(1)
            })
        }
    };



    render() {
        let {
            loading,
            dataAccess,
            totalAccess,
        } = this.state;


        console.log('dataAccess', dataAccess);

        return (
            <Layout className="dashboard-section-layout calendario-layout">
                <PageHeader className="dashboard-section-header nombreVista" title="Accesos"></PageHeader>
                <Content className="dashboard-section-content ">

                    <Row gutter={[16, 16]} className="mb-1">
                        <Col className="gutter-row" xs={24} md={12} xl={6}>
                            <Card data-type="red" className="card-access">
                                <IconAccess />
                                <Title>Entrada Visitas </Title>
                            </Card>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12} xl={6}>
                            <Card data-type="red" className="card-access">
                                <IconAccess />
                                <Title>Salida Visitas </Title>
                            </Card>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12} xl={6}>
                            <Card data-type="red" className="card-access">
                                <IconAccess />
                                <Title>Entrada Residentes </Title>
                            </Card>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12} xl={6}>
                            <Card data-type="red" className="card-access ">
                                <IconAccess />
                                <Title>Salida Residentes </Title>
                            </Card>
                        </Col>
                    </Row>
                    <div className=" div-contenedor">
                        <Row className="pd-1 row-enc-vista row-enc-accesos" gutter={[16, 16]}>
                            <Col xs={24} md={12} xl={8} >
                                <Search
                                    prefix={<IconSearch />}
                                    placeholder="Buscar en Accesos"
                                    className="input-search"
                                    onSearch={this.searchData}
                                />

                            </Col>
                            <Col xs={24} md={12} xl={6} className="filterDate ">
                                <RangePicker onChange={this.onDateChange} />
                            </Col>
                            <Col xs={24} md={12} xl={10} >
                                <Row >
                                    <Col xs={8} className="filters-drop ">
                                        <Select
                                            placeholder="Asunto"
                                            allowClear
                                            onChange={this.changeAsunto}
                                        >
                                            {this.state.asuntos.map(asuntos => {
                                                return <Option value={asuntos._id}>{asuntos.nombre}</Option>
                                            })}
                                        </Select>
                                        <IconSelectArrow />
                                    </Col>

                                    <Col xs={8} className="filters-drop ">
                                        <Select
                                            placeholder="Método"
                                            allowClear
                                            onChange={this.changeTipo}
                                        >
                                            <Option value={0}>Peaton</Option>
                                            <Option value={1}>Vehiculo</Option>
                                        </Select>
                                        <IconSelectArrow />
                                    </Col>

                                    <Col xs={8} className="filters-drop ">
                                        <Select
                                            placeholder="Casa"
                                            allowClear
                                            onChange={this.changeCasa}
                                        >
                                            {this.state.casas.map(casa => {
                                                return <Option value={casa._id}>{casa.nombre}</Option>
                                            })}
                                        </Select>
                                        <IconSelectArrow />
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        <Row 
                        style={{maxHeight:"460px", overflow:"auto", display:"block" }}
                        >

                            <List
                                className="list-item-access blackTheme"
                                dataSource={dataAccess}
                                loading={this.state.loading}

                                
                                renderItem={item => (
                                    <List.Item className="div-accesos-list">
                                        <Row className="item-access-row" >
                                            <Col span={1} className="muesca" ></Col>
                                            <Col span={3} className="item-access-row-column" >
                                                {item.nombre}
                                            </Col>
                                            <Col span={4} className="item-access-row-column">
                                                {item.colono_id?.nombre}
                                            </Col>
                                            <Col span={3} className="item-access-row-column">
                                                {item.tipo === 1 ? 'Vehiculo' : 'Peaton'}
                                            </Col>
                                            <Col span={6} className="item-access-row-column">
                                                {moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                            </Col>
                                            <Col span={5} className="item-access-row-column">
                                                {item.casa_id?.nombre}
                                            </Col>
                                            <Col span={3} className="item-access-row-column">
                                                <Link title="Detalle" className="accion-text" onClick={() => { this.abrirModalDetalle(item._id) }} > Ver Detalle</Link>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}

                                pagination={{
                                    showSizeChanger: false,
                                    onChange: page => {
                                        this.getAllAccess(page);
                                    },
                                    total: this.state.itemCount,
                                    pageSize: 10,
                                    className: "pagination-table "
                                }}
                            />
                        </Row>

                    </div>
                </Content>
                {/* Modal detalle */}
                <Modal
                    title="Detalle Acceso"
                    className="modal-aide top_up"
                    onCancel={this.handleCancel}
                    visible={this.state.detalleVisible}
                    destroyOnClose={true}
                    closable={false}
                    footer={false}
                    title={false}
                    width={"700px"}
                    footer={false}
                >
                    <Detalle id={this.state.admision_id} hideModal={this.handleCancel} />
                </Modal>

                {/* Modal Nueva Solicitud */}
                <Modal
                    title="Detalle Acceso"
                    className="modal-aide top_up"
                    onCancel={this.handleCancel}
                    visible={this.state.nuevaAdmisionVisible}
                    destroyOnClose={true}
                    closable={false}
                    footer={false}
                    title={false}
                    width={"700px"}
                >
                    <FormNuevaAdmision hideModal={this.handleCancel} admision_id={this.state.admision_id} />
                </Modal>

                {/* Modal Firma*/}
                <Modal
                    title="Pluma Acceso"
                    className="modal-aide modal-referencias modal-detCalendario modalDetalle modal-detAccesos top_up"
                    onCancel={this.handleCancel}
                    visible={this.state.firmaVisible}
                    destroyOnClose={true}
                    closable={false}
                    footer={false}
                    title={false}
                    width={"700px"}
                    footer={false}
                >
                    <FormNuevaAdmision />
                </Modal>

                {/* Modal Autorizada */}
                <ModalAutorizada
                    className="modal-aide"
                    visible={this.state.visitaAutorizadaVisible}
                    closeMethod={() => { this.setState({ visitaAutorizadaVisible: false }) }}
                    tipo={0}

                />

                {/* Nuevo Acceso Button */}
                <Button className="btnAddPlus" title="Nuevo registro" onClick={(e) => this.abrirModalCreacion()} >
                    <IconPlus />
                </Button>

            </Layout>

        );
    }
}
