//Dependencias
import React, { Component } from 'react';
import ReactEcharts from "echarts-for-react";
import echarts from 'echarts/lib/echarts'
import '../../global/css/Graficas.css';
import { line } from 'echarts/lib/theme/dark';

const axios = require('axios').default;

/**
 *
 * @class Chart_VisitantesActuales
 * @extends {Component}
 */
class Chart_VisitantesActuales extends Component {


	/**
	 *
	 * @memberof Chart_VisitantesActuales
	 * @data_VisitantesActuales 
	 */
	state = {
		data_VisitantesActuales: [
			{ name: "Visitantes", value: 0 },
			{ name: "Recurrentes", value: 0 }
		]
	};



	/**
	 *
	 * @memberof Chart_VisitantesActuales
	 * @description Manda a llamar la peticion para cargar los datos de los visitantes actuales
	 */
	componentDidMount = async () => {
		// axios.get('dashboard/grafica_VisitantesActuales', {
		// 	headers: { Authorization: sessionStorage.getItem("token") },
		// })
		// 	.then(response => {

		// 		if (response.data.success) {
		// 			var visitantes = response.data.data.Visitantes;
		// 			var recurrentes = response.data.data.Recurrentes;

		// 			this.setState({
		// 				data_VisitantesActuales: [
		// 					{ name: "Visitantes", value: visitantes },
		// 					{ name: "Recurrentes", value: recurrentes }
		// 				]
		// 			})
		// 		}
		// 	})
		// 	.catch(error => {
		// 		console.log(error)
		// 	});
	}


	/**
	 *
	 * @memberof Chart_VisitantesActuales
	 * @description Propiedades de la grafica
	 */
	visitantesActuales = () => ({
		tooltip: {
			trigger: 'item',
			formatter: '{a} <br/>{b}: {c} ({d}%)'
		},

		legend: {
			orient: 'horizontal',
			bottom: 0,
			left: 0,
			data: this.state.data_VisitantesActuales,
			textStyle: {
				color: '#CCC',
			}
		},

		toolbox: {
			show: true,
			feature: {
				saveAsImage: {
					show: true,
					title: 'Descargar',
					iconStyle: {
						borderColor: '#CCC',
					},
				},
			}
		},

		series: [
			{
				name: 'Total',
				type: 'pie',
				//selectedMode: 'single',
				radius: ['0%', '0%'],
				color: 'transparent',
				label: {
					position: 'inner',
					fontSize: '30',
					fontWeight: 'bold',
					backgroundColor: 'transparent',
					color: '#DDD',
					borderColor: 'transparent',
					margin: '-5px',
					formatter: '{c}'
				},

				labelLine: {
					show: true,
					padding: '5px',
				},

				data: [
					{
						name: 'Total',
						value: this.state.data_VisitantesActuales.reduce((obj, data) => { obj += data.value; return obj; }, 0)
					}
				],
			},
			{
				name: 'Visitantes',
				type: 'pie',
				radius: ['40%', '60%'],
				label: {
					show: true,
					position: 'outside',
					alignTo: 'edge',
					margin: 3,
					textStyle: { color: '#DDD' },
					formatter: '{c}\n({d}%)',
				},
				//    emphasis: { label: { show: true }},
				data: this.state.data_VisitantesActuales
			}
		]
	});

	render() {
		return (
			<ReactEcharts option={this.visitantesActuales()} style={{ height: 300 }} />
		);
	}
}



/**
 *
 *
 * @class Chart_PerfilVisitantes
 * @extends {Component}
 */
class Chart_PerfilVisitantes extends Component {



	/**
	 *
	 *
	 * @memberof Chart_PerfilVisitantes
	 * @data_PerfilVisitantes
	 * @data_PerfilVisitantesIndicador
	 */
	state = {

		data_PerfilVisitantes: [{ name: "Solicitudes", value: [0, 0, 0, 0, 0, 0] }],

		data_PerfilVisitantesIndicador: [
			{ name: "", max: 100 },
			{ name: "", max: 100 },
			{ name: "", max: 100 },
			{ name: "", max: 100 },
			{ name: "", max: 100 },
			{ name: "", max: 100 },
		]


	}


	/**
	 *
	 *
	 * @memberof Chart_PerfilVisitantes
	 * @description Manda la peticion para obtener los datos de los perfiles visitantes
	 */
	componentDidMount = async () => {

		// axios.get('dashboard/grafica_PerfilVisitante', {
		// 	headers: { Authorization: sessionStorage.getItem("token") },
		// })
		// 	.then(response => {

		// 		if (response.data.success) {
		// 			var regs = response.data.data.PerfilVisitante;
		// 			var dataRegs = [];
		// 			var dataRegsInd = [];

		// 			if (regs.length > 0) {
		// 				for (let index = 0; index < regs.length; index++) {
		// 					const reg = regs[index];
		// 					dataRegs.push(reg.solicitudes);

		// 					dataRegsInd.push({
		// 						name: reg.tipo,
		// 						max: response.data.data.Max
		// 					}
		// 					);
		// 				}

		// 				this.setState({
		// 					data_PerfilVisitantes: [{ name: "Solicitudes", value: dataRegs }],
		// 					data_PerfilVisitantesIndicador: dataRegsInd
		// 				})
		// 			}
		// 		}
		// 	})
		// 	.catch(error => {
		// 		console.log(error)
		// 	});
	}



	option = () => ({
		padding: 10,

		legend: {
			data: this.state.data_PerfilVisitantes.name,
			orient: 'horizontal',
			bottom: 0,
			left: 0,
			textStyle: {
				color: '#DDD',
			}

		},
		tooltip: {},

		toolbox: {
			show: true,
			feature: {
				saveAsImage: {
					show: true,
					title: 'Descargar',
					iconStyle: {
						borderColor: '#CCC',
					},
				},
			}
		},
		radar: {
			// shape: 'circle',
			width: "80%",
			name: {
				textStyle: {
					color: '#DDD',
					backgroundColor: 'transparent',
					borderRadius: 5,
					fontSize: "10px",
					margin: 0
				},
				position: 'top',
				alignTo: 'edge',
				margin: [10, 20],
			},

			indicator: this.state.data_PerfilVisitantesIndicador

		},
		series: [{
			type: 'radar',
			color: '#5eff5b',
			padding: "5px",
			label: {
				show: true,
				position: 'inside',
				alignTo: 'edge',
				margin: 0,
				textStyle: { color: '#fff', fontSize: 12, fontWeight: "bolder", backgroundColor: "rgba(0, 0, 0, 0.5)" },
			},
			// areaStyle: {normal: {}},
			data: this.state.data_PerfilVisitantes
		}]
	});

	render() {
		return (
			<ReactEcharts option={this.option()} style={{ height: 300 }} />
		);
	}
}


/**
 *
 *
 * @class Chart_AreasAcceso
 * @extends {Component}
 */
class Chart_AreasAcceso extends Component {


	/**
	 *
	 *
	 * @memberof Chart_AreasAcceso
	 * @data_AreasAcceso
	 */
	state = {
		data_AreasAcceso: [
			{ name: "", value: 0 },
		]
	};

	componentDidMount = async () => {


		// axios.get('/dashboard/grafica_AreasAcceso', {
		// 	headers: { Authorization: sessionStorage.getItem("token") },
		// })
		// 	.then(response => {
		// 		if (response.data.success) {
		// 			var regs = response.data.data.AreasAcceso;
		// 			var dataRegs = [];
		// 			console.log('regs', response);
		// 			if (regs.length > 0) {
		// 				for (let index = 0; index < 4; index++) {
		// 					const reg = regs[index];
		// 					dataRegs.push({
		// 						name: (reg.dep != null)?reg.dep:'',
		// 						value: reg.solicitudes
		// 					}
		// 					);
		// 				}
		// 				this.setState({ data_AreasAcceso: dataRegs });
		// 			}
		// 		}
		// 	})
		// 	.catch(error => {
		// 		console.log(error)
		// 	});
	}


	/**
	 *
	 *
	 * @memberof Chart_AreasAcceso
	 * @description Configuraciones de la grafica
	 */
	option = () => ({
		color: [
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#00ff894f' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#ff00a54f' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#0037ff5e' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#2a9f9e40' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#6665dd59' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#de0d923d' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#29e7cd4a' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#7ed32140' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#ff715b3d' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#fe333347' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#84aace4a' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#ff00ff4a' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#ffff004f' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#c000ff4f' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#10ff004f' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#ff99004f' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#ffc30059' }]),
			new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 1, color: 'transparent' }, { offset: 0.5, color: '#ff000042' }]),
		],


		textAlign: 'center',
		tooltip: {
			trigger: 'item',
			formatter: '{b} : {c} ({d}%)'
		},

		legend: {
			left: 'center',
			top: 'bottom',
			textStyle: { color: '#BBB' },
			data: this.state.data_AreasAcceso,
			margin: '3px 0px 0px 0px',

		},

		toolbox: {
			show: true,
			feature: {
				saveAsImage: {
					show: true, title: 'Descargar',
					width: '30px',
					height: '30px',
					iconStyle: {
						borderColor: '#CCC',
					},
				}
			}
		},


		series:
			[
				{
					name: 'Name',
					radius: '55%',
					type: 'pie',
					margin: 0,
					center: ['50%', '50%'],
					roseType: 'bar',
					clockwise: true,
					selectedMode: 'single',
					selectedOffset: 10,

					labelLine: {
						show: true,
						lineStyle: {

							type: 'dashed',
							type: 'doted',

							//fontSize:'15px',
							shadowOffsetX: 0,
							shadowOffsetY: 0,
							width: 3
						}
					},

					label: {
						show: true,
						position: 'outside',
						//alignTo: 'edge',
						alignTo: 'edge',
						margin: 5,
						textStyle: { color: '#CCC' },
						formatter: 'Solicitudes: {c}\n({d}%)',
						fontSize: '13px'
					},


					itemStyle: {
						shadowColor: 'rgba(0, 0, 0, 0.5)',
						borderColor: {},
						//borderColor:['#313133ac'],
					},

					data: this.state.data_AreasAcceso,

				}
			]
	});

	render() {

		return (
			<ReactEcharts option={this.option()} style={{ height: 300 }} />
		);
	}
}




/**
 *
 *
 * @class Chart_Barras
 * @extends {Component}
 */
class Chart_Barras extends Component {

	state = {
		dataAxis: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
		data: [0, 0, 0, 0, 0, 0, 0, 0],
		dataShadow: [10, 10, 10, 10, 10, 10, 10]
	};


	/**
	 *
	 *
	 * @memberof Chart_Barras
	 * 
	 */
	componentDidMount = async () => {

		// axios.get('/dashboard/app', {
		// 	headers: { Authorization: sessionStorage.getItem("token") },
		// })
		// 	.then(response => {
		// 		var semana = response.data.data.semana;
		// 		var data = [0, 0, 0, 0, 0, 0, 0, 0];

		// 		for (let index = 0; index < semana.length; index++) {
		// 			const element = semana[index];

		// 			var dia = 0;

		// 			switch (element.fecha) {
		// 				case "Monday":
		// 					dia = 0;
		// 					break;

		// 				case "Tuesday":
		// 					dia = 1;
		// 					break;

		// 				case "Wednesday":
		// 					dia = 2;
		// 					break;

		// 				case "Thursday":
		// 					dia = 3;
		// 					break;

		// 				case "Friday":
		// 					dia = 4;
		// 					break;

		// 				case "Saturday":
		// 					dia = 5;
		// 					break;

		// 				case "Sunday":
		// 					dia = 6;
		// 					break;
		// 			}

		// 			data[dia] = element.count;
		// 		}


		// 		this.setState({ data: data });
		// 	})
		// 	.catch(error => {
		// 		console.log(error)
		// 	});
	}


	/**
	 *
	 *
	 * @memberof Chart_Barras
	 * @description Configuraciones de la grafica
	 */
	option = () => ({
		padding: 5,

		tooltip: {
			//trigger: 'item',
			orient: 'vertical',
			left: 'center',
			top: 'center',
			formatter: '{b}: {c}'
		},

		xAxis: {
			data: this.state.dataAxis,
			axisTick: {
				show: false
			},

			axisLine: {
				show: false
			},

			axisLabel: {
				color: '#BBB',
				inside: false,
				position: 'absolute',
				rotate: '90',
				position: 'bottom',
				distance: '5',
				align: 'right',
				verticalAlign: 'middle',
				rotate: 90,
				fontSize: '11.5',
				//inside: true,
			},

			z: 1
		},
		yAxis: {
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				show: false,
				color: 'red',
				textStyle: {
					//color: '#CCC'              
				}
			}
		},

		toolbox: {
			show: true,
			feature: {
				saveAsImage: {
					show: true,
					title: 'Descargar',
					iconStyle: {
						borderColor: '#CCC',
					},
				},
			}
		},

		series: [
			{ // For shadow
				type: 'bar',
				itemStyle: {
					color: 'rgba(0,0,0,0.5)',
					borderColor: 'rgba(0,0,0,0.09)',
					barBorderRadius: 8
				},
				tooltip: {
					show: false
				},
				barGap: '-100%',
				barCategoryGap: '70%',
				data: this.state.dataShadow,
				animation: false,
				barBorderRadius: 8
			},
			{
				type: 'bar',
				label: {
					color: 'red'
				},
				itemStyle: {
					barBorderRadius: 8,
					color: '#2662f0'
				},

				emphasis: {
					itemStyle: {
						color: '#2662f0ba'
					}
				},
				data: this.state.data
			}
		]
	});

	render() {
		return (
			<ReactEcharts option={this.option()} style={{ height: 300 }} />
		);
	}
}



/************************************************************************************************************************************************ */
/************************************************************************************************************************************************ */
/************************************************************************************************************************************************ */

/*DASHBOARD */

/**
 * @class Chart_AsuntoVisitas
 * @extends {Component}
 */
class Chart_AsuntoVisitas extends Component {

	state = {
		data_AsuntoVisitas: [
			{ name: "Asunto 1", value: 8 },
			{ name: "Asunto 2", value: 7 },
			{ name: "Asunto 3", value: 6 },
			{ name: "Asunto 4", value: 5 },
			{ name: "Asunto 5", value: 4 },
			{ name: "Asunto 6", value: 3 },
			{ name: "Asunto 7", value: 2 },
			{ name: "Asunto 8", value: 1 },
		]
	};


	componentDidMount = async () => {

	}

	//Chart options
	asuntoVisitas = () => ({
		backgroundColor: "#0f0c28",


		toolbox: {
			show: true,
			feature: {
				saveAsImage: {
					show: true,
					title: 'Descargar',
					iconStyle: {
						borderColor: '#CCC',
					},
				},
			}
		},

		tooltip: {
			trigger: "item",
			formatter: "<strong>{b}</strong> = {c} "
		},

		series: [
			{
				type: "pie",
				animationDuration: 1000,
				animationEasing: "quarticInOut",
				radius: [10, 50],
				avoidLabelOverlap: false,
				startAngle: 90,
				hoverOffset: 4,
				center: ["50%", "50%"],
				roseType: "area",
				selectedMode: "multiple",
				label: {
					normal: {
						show: true,
						formatter: "{b}" // {c} data: [{value:},]
					},
					emphasis: {
						show: true
					}
				},
				labelLine: {
					normal: {
						show: true,
						smooth: false,
						length: 18,
						length2: 8
					},
					emphasis: {
						show: true
					}
				},
				data: this.state.data_AsuntoVisitas
			}
		]
	});

	render() {
		return (
			<ReactEcharts option={this.asuntoVisitas()} className="pie-chart" style={{ height: 180, maxWidth: 300, margin: "auto" }} />
		);
	}
}



/**
 * @class Chart_VisitasInmuebles
 * @extends {Component}
 */
class Chart_VisitasInmuebles extends Component {

	state = {
		data_VisitasInmuebles: [
			{ name: "Asunto 1", value: 8 },
			{ name: "Asunto 2", value: 7 },
			{ name: "Asunto 3", value: 6 },
			{ name: "Asunto 4", value: 5 },
			{ name: "Asunto 5", value: 4 },
			{ name: "Asunto 6", value: 3 },
			{ name: "Asunto 7", value: 2 },
			{ name: "Asunto 8", value: 1 },
		],
		data_Indicadores: ["A", "B", "C", "D", "E", "F", "G", "H"]
	};


	componentDidMount = async () => {

	}

	//Chart options
	chartVisitasInmuebles = () => ({
		backgroundColor: "#0f0c28",

		toolbox: {
			show: true,
			feature: {
				saveAsImage: {
					show: true,
					title: 'Descargar',
					iconStyle: {
						borderColor: '#CCC',
					},
				},
			}
		},
		xAxis: {
			type: "category",
			data: this.state.data_Indicadores
		},
		yAxis: {
			type: "value"
		},
		series: [
			{
				data: this.state.data_VisitasInmuebles,
				type: "bar",
				smooth: true,
				color: "#5c90f7"
			}
		],
		tooltip: {
			trigger: "axis"
		}
	});

	render() {
		return (
			<ReactEcharts option={this.chartVisitasInmuebles()} className="bar-chart" style={{ height: 180, maxWidth: 300, margin: "auto" }} />
		);
	}
}



/**
 *
 *
 * @class Chart_HorarioVisitas
 * @extends {Component}
 */
class Chart_HorarioVisitas extends Component {

	state = {
		dataAxis: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
		data: [9, 7, 5, 3, 1, 4, 6, 8],
		dataShadow: [10, 10, 10, 10, 10, 10, 10]
	};

	componentDidMount = async () => {
	}

	option = () => ({

		tooltip: {
			//trigger: 'item',
			orient: 'vertical',
			left: 'center',
			top: 'center',
			formatter: '{b}: {c}'
		},
		top: 0,
		height: 100,

		xAxis: {
			data: this.state.dataAxis,
			axisTick: {
				show: false
			},

			axisLine: {
				show: true
			},

			axisLabel: {
				show: false,
			},
		},
		yAxis: {
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				show: false,
			}
		},

		toolbox: {
			show: true,
			feature: {
				saveAsImage: {
					show: true,
					title: 'Descargar',
					iconStyle: {
						borderColor: '#CCC',
					},
				},
			}
		},

		series: [
			{ // For shadow
				type: 'bar',
				itemStyle: {
					color: 'rgba(0,0,0,0.5)',
					borderColor: 'rgba(0,0,0,0.09)',
					barBorderRadius: 8
				},
				tooltip: {
					show: false
				},
				barGap: '-100%',
				barCategoryGap: '65%',
				data: this.state.dataShadow,
				animation: false,
				barBorderRadius: 8,
				line: {
					show: false
				},
			},

			{
				type: 'bar',

				itemStyle: {
					barBorderRadius: 8,
					color: '#2662f0'
				},
				emphasis: {
					itemStyle: {
						color: '#2662f0ba'
					}
				},
				data: this.state.data
			}
		]
	});

	render() {
		return (
			<ReactEcharts option={this.option()} style={{ height: 180, maxWidth: 270, margin: "auto" }} />
		);
	}
}



/**
 * @class Chart_ClasificacionAccesos
 * @extends {Component}
 */
class Chart_ClasificacionAccesos extends Component {

	state = {
		data_Accesos: [
			{ name: "Acceso A", value: 50 },
			{ name: "Acceso B", value: 40 },
			{ name: "Acceso C", value: 30 },
			{ name: "Acceso D", value: 20 },
			{ name: "Acceso E", value: 10 },

		]
	};


	componentDidMount = async () => {

	}


	visitantesActuales = () => ({
		backgroundColor: "#0f0c28",

		tooltip: {
			trigger: 'item',
			formatter: '{a} <br/>{b}: {c} ({d}%)'
		},

		legend: {
			orient: 'horizontal',
			top: 0,
			left: 0,
			data: this.state.data_Accesos,
			textStyle: {
				color: '#CCC',
				fontSize: "10"
			}
		},

		toolbox: {
			show: true,
			feature: {
				saveAsImage: {
					show: true,
					title: 'Descargar',
					iconStyle: {
						borderColor: '#CCC',
					},
				},
			}
		},


		series: [
			{

				name: 'Total',
				type: 'pie',
				// 
				radius: ['0%', '0%'],
				color: 'transparent',
				top: "25%",

				label: {
					position: 'inner',
					fontSize: '20',
					fontWeight: 'bold',
					backgroundColor: 'transparent',
					color: '#DDD',
					borderColor: 'transparent',
					margin: '4px',
					formatter: '{c}',

				},


				// 
				data: [
					{
						name: 'Total',
						value: this.state.data_Accesos.reduce((obj, data) => { obj += data.value; return obj; }, 0)
					}
				],
			},

			{
				name: 'Clasificación',
				type: 'pie',
				radius: ['40%', '65%'],
				top: "25%",

				label: {
					normal: {
						show: false
					},
					emphasis: {
						show: true
					}
				},
				lableLine: {
					normal: {
						show: false
					},
					emphasis: {
						show: true
					}
				},



				data: this.state.data_Accesos
			}
		]
	});

	render() {
		return (
			<ReactEcharts option={this.visitantesActuales()} style={{ height: 180, maxWidth: 300, margin: "auto" }} />
		);
	}
}




export {
	Chart_Barras, Chart_VisitantesActuales, Chart_PerfilVisitantes, Chart_AreasAcceso,
	Chart_AsuntoVisitas, Chart_VisitasInmuebles, Chart_HorarioVisitas, Chart_ClasificacionAccesos
};
