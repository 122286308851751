//Dependencias
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Layout, Spin, Button } from 'antd'
import { SiderAdmin } from './componentes/Admin/Nav/nav'
import Dashboard from './componentes/Admin/Dashboard/dashboard'

import Accesos from './componentes/Admin/Accesos/accesos'
import Notificaciones from './componentes/Admin/Notificaciones/notificaciones'
import Calendario from './componentes/Admin/Calendario/calendario'

import Camaras from './componentes/Admin/Camaras/camaras'
import Historial from './componentes/Admin/Historial/historial'

import Header from './componentes/global/Header'

import Usuarios from './componentes/Usuarios/usuarios'

import UsuariosTipos from './componentes/Usuarios/usuariosTipos'

import Reportes from './componentes/Admin/Reportes/reportes'
import RegistroReportes from './componentes/Admin/Reportes/reporteCreacion'
import EdicionReportes from './componentes/Admin/Reportes/reporteEdicion'

import AsuntosList from './componentes/Admin/Asuntos/AsuntosList'

import Database from './componentes/Admin/Database/database'
import Mapa from './componentes/Admin/Mapa/Mapa';


import { SocketContext, socket } from './hooks/Socket';

import Logged from './hooks/UserLogged';

//assets
import './componentes/global/css/globalDashboard.css';
import {IconPlus} from './componentes/Widgets/Iconos'
const { Footer } = Layout;
const axios = require("axios").default;

export default class AdminRoutes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: false,
            isAdmin: false,
            toLogin: false,
            socket
        }

        if (sessionStorage.getItem('token') !== null && sessionStorage.getItem('userId') !== null)
            this.state.socket.emit("start", sessionStorage.getItem("token"))
    }

    componentDidMount = async () => {

        console.log("dashboard", sessionStorage);

        if (sessionStorage.getItem('token') !== null && sessionStorage.getItem('userId') !== null) {


            let user_id = sessionStorage.getItem('userId');
            axios.post("/usuarios/id", {
                id: user_id
            }, {
                headers: { Authorization: sessionStorage.getItem("token") }
            })
                .then(response => {

                    if (response.data.data.roles_id != undefined ) {
                        this.setState({ user: { ...response.data.data, isAdmin: false } })
                    }

                    else if (response.data.data.roles_id != undefined && response.data.data.roles_id.nombre === "Administrador") {
                        this.setState({ user: { ...response.data.data, isAdmin: true } })
                    }

                    else {
                        this.setState({ toLogin: true })
                    }

                })

                .catch(error => {
                    this.setState({ toLogin: true })
                });
        } else {
            this.setState({ toLogin: true })
        }
    }

    createVisitante=()=>{

        console.log('creando visitante.....');
    }
    render() {
        if (this.state.toLogin) {
            return <Redirect to="/login" />
        }
        return (
            <Logged.Provider value={this.state.user}>
                <SocketContext.Provider value={this.state.socket}>

                    <Spin spinning={this.state.loading}>
                        <Layout className="divLayout">
                            <Header setLoading={value => this.setState({ loading: value })} />
                            <Layout hasSider className="pt-1 pl-1 ">
                                <SiderAdmin />
                                <Switch>
                                    <Route exact path="/admin/dashboard" component={Dashboard} />
                                    <Route exact path="/admin/accesos" render={(props) => <Accesos  {...props} user={this.state.user} />} />

                                    <Route exact path="/admin/notificaciones" component={Notificaciones} />

                                    {/* <Route exact path="/admin/calendario" component={Calendario} /> */}
                                    
                                    <Route exact path="/admin/database" component={Database} />

                                    <Route exact path="/admin/camaras" component={Camaras} />
                                    <Route exact path="/admin/mapa" component={Mapa} />
                                    <Route exact path="/admin/historial" component={Historial} />

                                    <Route exact path="/admin/usuarios/" component={Usuarios} />

                                    <Route exact path="/admin/usuarios/tipos" component={UsuariosTipos} />

                                    <Route exact path="/admin/reportes/" component={Reportes} />
                                    <Route exact path="/admin/reportes/creacion" component={RegistroReportes} />
                                    <Route exact path="/admin/reportes/edicion/:id" component={EdicionReportes} />

                                    <Route exact path="/admin/asuntos/" component={AsuntosList} />
                                    {/* <AdminRoutes /> */}
                                </Switch>

                           
                            </Layout>
                            {/* <Footer className="divFooter">&copy; AIDE 2021</Footer> */}
                        </Layout>

                    </Spin>

                </SocketContext.Provider>
            </Logged.Provider>
        );
    }
}

