import React, { Component } from "react";
import { Row, Col, Modal, Typography, message, Button } from 'antd';
import moment from 'moment';

import { IconSelectArrowModal , IconClose} from './../Widgets/Iconos';

import './../global/css/Accesos.css';
import './../global/css/UpdateModales.css';


const { Title, Text } = Typography;
const axios = require('axios').default;

export default class ModalUsuarioDetalle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            loading: false,

            idReg: "",
            busqueda: true,
            dataItem: []

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    componentDidUpdate() {
        if (this.props != undefined && this.props.id != undefined && this.props.id != "" && this.props.visible && this.state.busqueda) {

            setTimeout(() => {
                this.getInfo();
            }, 100);
        }
    }


    hideModal = () => {
        this.props.onCancel()

        this.setState({
            visible: false,
            idReg: "",
            busqueda: true,
            dataItem: []
        })
    }


    getInfo = async (id) => {
        if (this.state.busqueda) {
            this.setState({ loading: true, busqueda: false })

            let id = this.props.id;
            axios.post("/usuarios/id", { id: id },
                {
                    headers: { Authorization: sessionStorage.getItem("token") }
                })

                .then(res => {
                    console.log(res)
                    this.setState({
                        dataItem: res.data.data,
                        loading: false,
                        busqueda: false,
                        visible: true
                    })

                })
                .catch(res => {
                    message.error("error", res.data);
                });

        }
    }

    render() {
        let { visible, dataItem } = this.state;

        let tipoUser = "N/A", fecha ="", casa = ""
        if (dataItem._id != undefined) {
            if (dataItem.roles_id != undefined) {
                tipoUser = dataItem.roles_id.nombre
            }
        
            fecha = moment(dataItem.createdAt).format('DD-MM-YYYY')

            if(dataItem.casa_id != undefined) {
                casa= dataItem.casa_id.nombre;
            }
        }

        return (
            <Modal
                title="Detalle Usuario"
                visible={visible}
                className="modal-aide modal-detCalendario modalDetalle modal-detAccesos modal-form"
                onCancel={this.hideModal}
                destroyOnClose={true}
                width={"500px"}
                title={false}
                footer={false}
            >

        
                <Row className="row-enc">
					<Col span={24} className='modal-header'>
						<Button className="btn-close-modal" onClick={this.hideModal}>
							<IconClose/>	
						</Button>
						<img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal"/>
					</Col>
          
					<Col span={24} className="center">
						<Title  style={{fontSize:"26px"}} className="modal-title">Detalle Usuario</Title>
					</Col>
				</Row>

                <Row className="row-info"
                >
                    <Col xs={23} lg={11} offset={1} >
                        <p> Nombre </p>
                        <label>{dataItem.nombre} </label>
                    </Col>

                    <Col xs={23} lg={11} offset={1} >
                        <p> Teléfono </p>
                        <label>{dataItem.telefono} </label>
                    </Col>

                    <Col xs={23} lg={11} offset={1} >
                        <p> Email </p>
                        <label>{dataItem.email} </label>
                    </Col>

                    <Col xs={23} lg={11} offset={1} >
                        <p> Nivel </p>
                        <label>{tipoUser} </label>
                    </Col>

                    <Col xs={23} lg={11} offset={1} >
                        <p> Casa </p>
                        <label>{casa} </label>
                    </Col>

                    <Col xs={23} lg={11} offset={1} >
                        <p> Fecha creación </p>
                        <label>{fecha} </label>
                    </Col>

                </Row>
            </Modal>
        )
    }
}

