import React, { Component } from "react";
import { Form, Input, Button, Layout,  Row, Col, message,PageHeader } from 'antd';
import { Icon } from '@ant-design/compatible';
import { Redirect } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";

const {  Content } = Layout;
const axios = require("axios").default;


/**
 *
 *
 * @class CreacionReporte
 * @extends {Component}
 */
class CreacionReporte extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
    }

    handleSubmit = values => {
        console.log(values);

        axios.post('/reportes/add',
            {
                nombreCompleto: values.nombre,
                motivo: values.descripcion
            },
            {
                headers: { Authorization: sessionStorage.getItem("token") },
            }
        )
            .then((response) => {
                if (response.data.success) {
                    message.success(response.data.message);
                    this.setState({ redirect: true });
                }
            })
            .catch((error) => {
                console.log(error)

            })
    }


    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/admin/reportes" />;
        }
    };

    render() {

        return (
            <Layout className="divContenedorLayout">
                {this.renderRedirect()}
                <PageHeader className="dashboard-section-header nombreVista" title="Reportes"></PageHeader>
                <Content>
                    <div className="DeptoRegister">
                        <div className="divForm">
                                <Form onFinish={this.handleSubmit} className="register-form" name="form-crearProv" ref={this.formRef} layout={"vertical"}>
                                    <Row align="center">
                                        <h3 className="nombreVista encabezado" >Creación de reporte</h3>
                                    </Row>
                                    <Row style={{ marginBottom: '0px', paddingBottom: '0px' }}>
                                        <Col xs={24} lg={{ span: 12, offset: 6 }} >
                                            <Form.Item name="nombre" label="Nombre" rules={[{ required: true, message: "Ingrese nombre" }]} >
                                                <Input prefix={<Icon type="" />} placeholder="Nombre" />
                                            </Form.Item >
                                        </Col>

                                        <Col xs={24} lg={{ span: 12, offset: 6 }} >
                                            <Form.Item name="descripcion" label="Descripción" rules={[{ required: true, message: "Ingrese descripción" }]}  >
                                                <TextArea prefix={<Icon type="" />} placeholder="Descripción" />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row align="center" style={{ marginTop: '0px', paddingTop: '0px' }} >
                                        <Form.Item className="btn-form" style={{ marginTop: '0px', paddingTop: '0px' }} >
                                            <Button type="primary" htmlType="submit" className="ant-btn btn btn-azul" style={{ marginTop: '20px', paddingTop: '0px' }}  > Guardar </Button>
                                        </Form.Item>
                                    </Row>
                                </Form>
                        </div>
                    </div>
                </Content>
            </Layout>
        );
    }
}

export default CreacionReporte;
