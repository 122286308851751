import React, { Component } from 'react';
import { Modal, Row, Col, Button, Typography, Form, Input, Select, Upload, Divider, DatePicker, } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
//componentes
import { IconClose, IconSelect } from '../../Widgets/Iconos';
//css
import '../../global/css/modalesTEMP.css'

const moment = require('moment');
const axios = require("axios");

const { Title, Text } = Typography;
const { Option } = Select;



/**
 *
 *
 * @class ModalDetectado
 * @extends {Component}
 */
class ModalDetectado extends Component {
	/**
	 *Creates an instance of ModalDetectado.
	 * @param {*} props
	 * @memberof ModalDetectado
	 */
	constructor(props) {
		super(props);


		this.state = {
			usuario: {},

			casas: [],
			residente: null,
			casa: null,

			status: 1,
			asuntos: [],
			currentPage: 1,
			hora_entrada: moment(),

			admision: {},
			tipo: 0,
			consultado: false,
			cantidad: 0
		}
	}

	asuntosRef = React.createRef();
	formSolicitudRef = React.createRef();

	/**
	 *
	 *
	 * @memberof ModalDetectado
	 * @description Manda a llamar las casas existentes en el sistema
	 */
	componentDidMount() {

		axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
		this.getCasas();
		this.getAsuntos();

		// si no hay admision para cargar y no hay datos previos cargados
		if (this.props.admision_id != null && this.props.admision != undefined) {
			this.getAdmision(this.props.admision_id)
		}

		//si los datos previos existen
		else {
			let admision = this.props.admision;
			admision.cantidad = this.props.cantidad;
			this.setState({ admision: admision, tipo: admision.tipo })
		}

	}


	componentDidUpdate = () => {
		console.log(this.props);

		if (this.props.visible) {

			if (!this.state.consultado) {

				// si no hay admision para cargar y no hay datos previos cargados
				if (this.props.admision_id != null && this.props.admision != undefined && this.state.admision?._id !== this.props.admision_id) {
					this.getAdmision(this.props.admision_id);
					this.setState({ consultado: true })

				}

				//si los datos previos existen
				else {
					let admision = this.props.admision;
					admision.cantidad = this.props.cantidad;
					this.setState({ admision: admision, tipo: admision.tipo, consultado: true })
				}
			}
		}

	}

	/**
	 *
	 *
	 * @param {*} id
	 * @memberof ModalDetectado
	 */
	getAdmision = async (id) => {
		axios.get('/admision', {
			params: { _id: id }
		})
			.then((rec) => {

				var data = (rec.data.data._doc !== undefined) ? rec.data.data._doc : rec.data
				var cantidad = (rec.data.data._doc !== undefined) ? rec.data.data.cantidad : 0

				console.log("-----------------");
				console.log(data);

				this.setState({
					tipo: data.tipo,
					admision: data,
					consultado: true,
					cantidad: cantidad
				});

				console.log(this.formSolicitudRef);


				this.formSolicitudRef.current.setFieldsValue({
					asunto: data.asunto_id._id,
					casa: data.casa_id._id,
					hora_entrada: data.createdAt,
					nombre: data.nombre,
					numero_visitas: cantidad
				});
			})
			.catch(error => {
				console.log('error', error);
			})
	}

	/**
	 *
	 *
	 * @memberof ModalDetectado
	 */
	setAdmision = (status) => {
		this.state.status = status;
		this.setState({ status: status });
	}



	/**
	 *
	 *
	 * @memberof ModalDetectado
	 * @description
	 */
	getCasas = () => {
		axios.get('casas')
			.then(res => {
				this.setState({ casas: res.data.data })
			})
			.catch(error => {
				console.log('error al cargar casas', error)
			});
	}

	/**
	 *
	 *
	 * @memberof ModalDetectado
	 */
	getAsuntos = (search) => {
		axios.get('/asuntos', {
			params: { search: search }
		})
			.then((res) => {
				const dataList = res.data.data.itemsList;
				const data = res.data.data.paginator;
				this.setState({ asuntos: dataList, currentPage: data.currentPage });
			})
			.catch((error) => {
				console.log('no se pudo cargar los asuntos', error.response);
			});

	}


	/**
	 *
	 *
	 * @memberof ModalDetectado
	 */
	addAsunto = () => {
		var nombre = this.asuntosRef.current.state.value;
		axios.post('asuntos/add',
			{ nombre }
		).then(res => {
			this.getAsuntos();
		}).catch(error => {
			console.log('error al guardar asunto', error.response)
		}).finally(
			this.asuntosRef.current.state.value = null
		);
	}

	/**
	 *
	 *
	 * @memberof ModalDetectado
	 * @description Trae la informacion del residente que habita la casas seleccionada
	 */
	getResidenteCasa = (id) => {
		axios.post('residentes/casa', {
			casa_id: id
		})
			.then(res => {
				this.setState({ residente: res.data.data[0] });
			})
			.catch(error => {
				console.log('error al traer el residente', error);
			});
	}

	/**
	 *
	 *
	 * @memberof ModalDetectado
	 * @description Actualiza el state casa para la casa seleccionada que se desea visitar
	 */
	onChangeCasa = (value) => {
		this.setState({ casa: value });
		this.getResidenteCasa(value);
	}


	/**
	 *
	 *
	 * @memberof ModalDetectado
	 */
	onChangetipo = (value) => {
		var tipo = value.target.value
		this.setState({ tipo: tipo });
	}


	/**
	 *
	 *
	 * @returns </Row>
	 * @memberof ModalDetectado
	 * @description Renderiza la informacion del residente 
	 */
	renderResidenteInfo() {
		if (this.state.casa != null && this.state.residente != null) {
			return <Row align="center" gutter={[16, 16]}>
				<Col span={24}>
					<Text className="subtitle-modal">Información del Residente </Text>
					<Divider style={{ borderWidth: 1, borderColor: '#899AA4', margin: 5 }} />
				</Col>
				{/* 
				<Col span={11}>
					<Form.Item name="nombre_residente" label="Nombre" >
						<Text className="modal-text">{this.state.residente.nombre}</Text>
					</Form.Item>
				</Col> 
				*/}

				<Col span={11} >
					<Form.Item name="telefono_residente" label="Télefono" >
						<Text className="modal-text">{this.state.residente.telefono}</Text>
					</Form.Item>
				</Col>
			</Row>

		}

	}


	/**
	 *
	 *
	 * @memberof ModalDetectado
	 * @description Renderiza los campos segun el tipo de solicitud manual
	 */
	renderTipo() {
		console.log(this.state.tipo)
		if (this.props.admision_id === null) {

			if (this.state.tipo === 1) {
				return <Row align="center" justify="start" gutter={[16, 16]}>
					<Col span={24} style={{ padding: '0px 30px' }}>
						<Text className="subtitle-modal">Información del Vehiculo </Text>
						<Divider style={{ borderWidth: 1, borderColor: '#899AA4', margin: 5 }} />
					</Col>
					<Col span={22}  >
						<Form.Item name="descripcion" label="Descripción del Vehiculo"  >
							<Input className="input-modal" placeholder="Descripción " />
						</Form.Item>
					</Col>
					<Col span={11} >
						<Form.Item name="datos" label="Placas" >
							<Text className="modal-text">{this.state.admision?.datos}</Text>
						</Form.Item>
					</Col>

					<Col span={11} >
						<Form.Item name="marca" label="Marca">
							<Input className="input-modal" placeholder="Marca " />
						</Form.Item>
					</Col>
					<Col span={11} >
						<Form.Item name="modelo" label="Modelo">
							<Input className="input-modal" placeholder="Modelo " />
						</Form.Item>
					</Col>



					{/* <Col span={11} >
						<Form.Item name={'fecha_nacimiento'} label="Fecha de nacimiento"  >
							<DatePicker className="input-modal" />
						</Form.Item>
					</Col> */}
				</Row>
			}

			else {


				return <Row align="center" justify="start" gutter={[16, 16]}>
					<Col span={24} style={{ padding: '0px 30px' }}>
						<Text className="subtitle-modal">Información del Peatón  </Text>
						<Divider style={{ borderWidth: 1, borderColor: '#899AA4', margin: 5 }} />
					</Col>
					<Col span={22}  >
						<Form.Item name={'descripcion'} label="Datos del peatón"  >
							<Input className="input-modal" placeholder="Datos del Peatón" />
						</Form.Item>
					</Col>

					<Col span={11}  >
						<Form.Item name={'datos'} label="Huella del peatón" >
							<Text className="modal-text">{this.state.admision?.datos}</Text>
						</Form.Item>
					</Col>


					{/* <Col span={11} >
						<Form.Item name={'fecha_nacimiento'} label="Fecha de nacimiento"  >
							<DatePicker className="input-modal" />
						</Form.Item>
					</Col> */}
				</Row>

			}

		}
	}

	/**
	 *
	 *
	 * @memberof ModalDetectado
	 * @description Cierra el modal
	 */
	hideModal = (notificar) => {
		this.props.hideModal(notificar);
		this.setState({
			admision: {}
		})
	}


	noAutorizar = (id) => {
		this.props.NoAutorizar(id)
	}


	/**
 *
 * @memberof ModalTransaccion
 *
 * @method normFile
 * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
 *
 * @param images (string)
 * Recibe el nombre de la imagen.
 */
	normFile = (e) => {
		const { file, fileList } = e;
		let upload = false;

		for (let x = 0; x < fileList.length; x++) {
			if (fileList[x].response) {
				fileList[x].name = fileList[x].response.filename
			}

			if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
				this.state.loading = true;
				this.setState({ update: !this.state.update })
				break;
			}
		}

		/**
		 * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
		 */
		if (file.status == "done") {
			this.setState({
				image: {
					url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
					name: e.file.response.filename
				}
			});

		}
		if (file.status == "removed")
			this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name);
		return e && e.fileList;
	};



	/**
	 *
	 * @memberof ModalTransaccion
	 *
	 * @method removeFile
		 * @description Elimina un archivo del WebService.
	 *
	 * @param images (string)
	 * Recibe el nombre de la imagen.
	 */

	removeFile = (image) => {
		axios.post("/upload/delete", {
			filename: image
		})
			.then(res => {
				console.log("imagen removida con exito", res);
			})
			.catch(res => {
				console.log("imagen no se puedo remover", res);
			})
	};


	/**
	 *
	 *
	 * @memberof ModalDetectado
	 * @description
	 */
	onFinish = (values) => {

		const { admision, residente, status, hora_entrada } = this.state;
		axios.post('admision/registrar',
			{
				id: ((this.props.admision_id !== undefined) ? this.props.admision_id : null),
				datos: admision.datos,
				tipo: admision.tipo,
				hora_entrada: hora_entrada,
				status: status,

				asunto_id: values.asunto,
				casa_id: values.casa,
				descripcion: values.descripcion,
				colono_id: residente?._id,

				//visitante info
				nombre: values.nombre,
				fecha_nacimiento: values.fecha_nacimiento,
				visitante_id: null,
				comprobantes: values?.comprobantes?.map(e => e.name),

			},
			{
				headers: { Authorization: sessionStorage.getItem("token") },
			})
			.then(res => {
				let is_residente = res.data.residente;
				if (is_residente == false) {
					let admision = res.data.data;
					this.props.setAcceso(admision._id, hora_entrada);
					if (status != 0) {
						this.hideModal(true);//para disparar notificacion del residente
					}
					else {
						this.hideModal(false);//para disparar notificacion del residente
						this.noAutorizar(admision._id);
					}
				}
				else {
					let asistencia = res.data.data;
					this.props.setAcceso(asistencia.admision_id, hora_entrada);
					this.props.addAcceso(true);
					this.hideModal(false);//para no lanzar la notificacion de residente
				}
			})
			.catch(error => {
				console.log('solicitud admision no se pudo guardar', error);
			})
	}


	render() {

		let admision = this.state.admision;
		console.log("*************************************************************")
		console.log(admision)
		console.log("*************************************************************")


		return (
			<Form onFinish={this.onFinish} className="modal-form" ref={this.formSolicitudRef}>
				<Row>
					<Col span={24} className='modal-header'>
						<Button className="btn-close-modal" onClick={this.props.hideModal}>
							<IconClose />
						</Button>
						<img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal" />
					</Col>
					<Col span={24} className="center mt-1 mb-1">
						<Title level={3} className="modal-title">
							{(this.state.tipo == 0) ? "Peaton Detectado" : "Vehiculo Detectado"}
						</Title>
					</Col>
				</Row>
				<Row align="center" gutter={[16, 16]}>
					<Col span={11}>
						<Form.Item name="nombre" label="Nombre Visita" rules={[{ required: true, message: "Debe escribir el nombre del visitante." }]}>
							<Input className="input-modal" />
						</Form.Item>
					</Col>
					<Col span={11} >
						<Form.Item name="hora_entrada" label="Fecha y Hora" >
							<Text className="modal-text" >{moment(this.state.hora_entrada).format('DD/MM/YYYY, hh:mm:ss A')}</Text>
						</Form.Item>
					</Col>
				</Row>
				<Row align="center" gutter={[16, 16]}>
					<Col span={11}>
						<Form.Item name="asunto" label="Asunto" rules={[{ required: true, message: "Debe seleccionar un asunto." }]}>
							<Select
								className="input-modal"
								placeholder="Buscar.."
								showSearch
								defaultActiveFirstOption={false}
								showArrow={false}
								filterOption={false}
								onSearch={(search) => this.getAsuntos(search)}
								notFoundContent={<p>No se encontro el Asunto</p>}
								dropdownRender={menu => (
									<div>
										{menu}
										<Divider style={{ margin: '4px 0' }} />
										<div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
											<Input style={{ flex: 'auto' }} ref={this.asuntosRef} />
											<a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.addAsunto} >
												<PlusOutlined /> Añadir
											</a>
										</div>
									</div>
								)}
							>
								{this.state.asuntos.map(function (asunto, index) {
									return <Option value={asunto._id}>{asunto.nombre}</Option>
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col span={11} >
						<Form.Item name="casa" label="Casa" rules={[{ required: true, message: "Debe seleccionar una casa." }]}>
							<Select className="select-modal" suffixIcon={<IconSelect />} onChange={this.onChangeCasa}>
								{this.state.casas.map(casa => {
									return <Option value={casa._id}>{casa.nombre}</Option>
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				{this.renderResidenteInfo()}

				{this.renderTipo()}

				<Row align="center" gutter={[16, 16]}>
					<Col span={11}>
						<Form.Item label="Identificación:" name="comprobantes" getValueFromEvent={this.normFile} valuePropName="fileList" className="content-uploader">
							<Upload className="avatar-uploader" action={axios.defaults.baseURL + "upload/add"} multiple={true} >
								<Button icon={<UploadOutlined />}>Identificación</Button>
							</Upload>
						</Form.Item>
					</Col>
					<Col span={11} >
						<Form.Item name="numero_visitas" label="Número de visitas" >
							<Text className="modal-text">{this.state.cantidad}</Text>
						</Form.Item>
					</Col>
				</Row>

				<Row align="center" justify="space-around">
					<Col span={11}>
						<Button type="primary" htmlType="submit" className="btn- btn-denied" onClick={() => this.setAdmision(0)}>
							Rechazar Solicitud
						</Button>
					</Col>

					<Col span={11} >
						<Button type="primary" htmlType="submit" className="btn- btn-accept" onClick={() => this.setAdmision(2)}>
							Aprobar Solicitud
						</Button>
					</Col>
				</Row>
			</Form >
		);
	}


}


/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

	return <Modal
		className="modal-aide"
		visible={props.visible}
		closable={false}
		footer={false}
		destroyOnClose={true}
		onCancel={props.hideModal}
		width={700}
	>

		<ModalDetectado {...props} />
	</Modal>
}