import React, { Component } from 'react';
import { Modal, Row, Col, Button, Typography, Form, Input, Select, Upload, Divider, Radio, message, DatePicker, InputNumber } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
//componentes
import { IconClose, IconSelect } from '../../../Widgets/Iconos';
//css
import '../../../global/css/modalesTEMP.css'
const moment = require('moment');
const axios = require("axios");
const { Title, Text } = Typography;
const { Option } = Select;



/**
 * @class FormNuevaAdmision
 * @extends {Component}
 */
export default class FormNuevaAdmision extends Component {
    /**
     *Creates an instance of FormNuevaAdmision.
     * @param {*} props
     * @memberof FormNuevaAdmision
     */
    constructor(props) {
        super(props);


        this.state = {
            usuario: {},
            casas: [],
            residente: null,
            casa: null,

            status: 1,
            asuntos: [],
            currentPage: 1,
            hora_entrada: moment(),

            admision: {},
            tipo: 0
        }
    }

    asuntosRef = React.createRef();


    /**
     * @memberof FormNuevaAdmision
     * @description Manda a llamar las casas existentes en el sistema
     */
    componentDidMount() {
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
        this.getCasas();
        this.getAsuntos();

        // si no hay admision para cargar y no hay datos previos cargados
        if (this.props.admision_id != null) {
            this.getAdmision(this.props.admision_id)
        }

    }

    /**
     * @param {*} id
     * @memberof FormNuevaAdmision
     */
    getAdmision(id) {
        axios.get('/admision', {
            params: { _id: id }
        })
            .then((rec) => {

                var data = (rec.data.data._doc !== undefined) ? rec.data.data._doc : rec.data
                this.setState({ admision: data, tipo: data.tipo });
            })
            .catch(error => {
                console.log('error', error);
            })
    }

    /**
     * @memberof FormNuevaAdmision
     * @description
     */
    getCasas = () => {
        axios.get('casas')
            .then(res => {
                this.setState({ casas: res.data.data })
            })
            .catch(error => {
                console.log('error al cargar casas', error)
            });
    }

    /**
     * @memberof FormNuevaAdmision
     */
    getAsuntos = (search) => {
        axios.get('/asuntos', {
            params: { search: search }
        })
            .then((res) => {
                const dataList = res.data.data.itemsList;
                const data = res.data.data.paginator;
                this.setState({ asuntos: dataList, currentPage: data.currentPage });
            })
            .catch((error) => {
                console.log('no se pudo cargar los asuntos', error.response);
            });
    }

    /**
     * @memberof FormNuevaAdmision
     */
    addAsunto = () => {
        var nombre = this.asuntosRef.current.state.value;
        axios.post('asuntos/add',
            { nombre }
        ).then(res => {
            this.getAsuntos();
        }).catch(error => {
            console.log('error al guardar asunto', error.response)
        }).finally(
            this.asuntosRef.current.state.value = null
        );
    }

    /**
     * @memberof FormNuevaAdmision
     * @description Trae la informacion del residente que habita la casas seleccionada
     */
    getResidenteCasa = (id) => {
        axios.post('residentes/casa', {
            casa_id: id
        })
            .then(res => {
                this.setState({ residente: res.data.data[0] });
            })
            .catch(error => {
                console.log('error al traer el residente', error);
            });
    }

    /**
     * @memberof FormNuevaAdmision
     * @description Actualiza el state casa para la casa seleccionada que se desea visitar
     */
    onChangeCasa = (value) => {
        this.setState({ casa: value });
        this.getResidenteCasa(value);
    }


    /**
     * @memberof FormNuevaAdmision
     */
    onChangetipo = (value) => {
        var tipo = value.target.value
        this.setState({ tipo: tipo });
    }


    /**
     * @returns </Row>
     * @memberof FormNuevaAdmision
     * @description Renderiza la informacion del residente 
     */
    renderResidenteInfo() {
        if (this.state.casa != null && this.state.residente != null) {
            return <Row align="center" gutter={[16, 16]} style={{ padding: "0px 10px" }}>
                <Col span={24}>
                    <Text className="subtitle-modal">Información del Residente </Text>
                    <Divider style={{ borderWidth: 1, borderColor: '#899AA4', margin: 5 }} />
                </Col>
                {/*
                <Col span={11}>
                     <Form.Item name="nombre_residente" label="Nombre" >
                        <Text className="modal-text">{this.state.residente.nombre}</Text>
                    </Form.Item> 
                </Col>
                    */}

                <Col span={22} style={{ paddingLeft: "10px" }}>
                    <Form.Item name="telefono_residente" label="Télefono" >
                        <Text className="modal-text">{this.state.residente.telefono}</Text>
                    </Form.Item>
                </Col>
            </Row>

        }
    }




    /**
     * @memberof FormNuevaAdmision
     * @description Renderiza los campos segun el tipo de solicitud manual
     */
    renderTipo() {
        if (this.props.admision_id === null) {
            if (this.state.tipo === 1) {
                return <Row align="center" justify="start" gutter={[16, 16]}>
                    <Col span={24} style={{ padding: '0px 30px' }}>
                        <Text className="subtitle-modal">Información del Vehiculo </Text>
                        <Divider style={{ borderWidth: 1, borderColor: '#899AA4', margin: 5 }} />
                    </Col>

                    <Col span={22}  >
                        <Form.Item name="descripcion" label="Descripción del Vehiculo"  >
                            <Input className="input-modal" placeholder="Descripción " />
                        </Form.Item>
                    </Col>

                    <Col span={11} >
                        <Form.Item name="datos" label="Placas" rules={[{ required: true, message: "Debe escribir las placas del vehiculo" }]}>
                            <Input className="input-modal" placeholder="Placas " />
                        </Form.Item>
                    </Col>

                    <Col span={11} >
                        <Form.Item name="marca" label="Marca" rules={[{ required: true, message: "Debe escribir la marca del vehiculo" }]}>
                            <Input className="input-modal" placeholder="Marca " />
                        </Form.Item>
                    </Col>
                    <Col span={11} >
                        <Form.Item name="modelo" label="Modelo" rules={[{ required: true, message: "Debe escribir el modelo del vehiculo" }]}>
                            <Input className="input-modal" placeholder="Modelo " />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Identificación:" name="comprobantes" getValueFromEvent={this.normFile} valuePropName="fileList" className="content-uploader">
                            <Upload className="avatar-uploader" action={axios.defaults.baseURL + "upload/add"} multiple={true} >
                                <Button icon={<UploadOutlined />}>Identificación</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    {/* <Col span={11} >
                        <Form.Item name={'fecha_nacimiento'} label="Fecha de nacimiento"  >
                            <DatePicker className="input-modal" />
                        </Form.Item>
                    </Col> */}

                </Row>

            }
            else {
                return <Row align="center" justify="start" gutter={[16, 16]}>
                    <Col span={24} style={{ padding: '0px 30px' }}>
                        <Text className="subtitle-modal">Información del Peatón </Text>
                        <Divider style={{ borderWidth: 1, borderColor: '#899AA4', margin: 5 }} />
                    </Col>
                    <Col span={22}  >
                        <Form.Item name={'descripcion'} label="Datos del peatón"  >
                            <Input className="input-modal" placeholder="Datos del Peatón" />
                        </Form.Item>
                    </Col>
                    <Col span={11}  >
                        <Form.Item name={'datos'} label="Huella del peatón" rules={[{ required: true, message: "Debe tomar la huella del peaton." }]}>
                            <Input className="input-modal" placeholder="Datos del Peatón" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Identificación:" name="comprobantes" getValueFromEvent={this.normFile} valuePropName="fileList" className="content-uploader">
                            <Upload className="avatar-uploader" action={axios.defaults.baseURL + "upload/add"} multiple={true} >
                                <Button icon={<UploadOutlined />}>Identificación</Button>
                            </Upload>
                        </Form.Item>
                    </Col>


                    {/* <Col span={11} >
                        <Form.Item name={'fecha_nacimiento'} label="Fecha de nacimiento"  >
                            <DatePicker className="input-modal" />
                        </Form.Item>
                    </Col> */}
                </Row>

            }
        }
    }
    /**
     *
     * @memberof FormNuevaAdmision
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;
        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename
            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status == "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status == "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };



    /**
     *
     * @memberof FormNuevaAdmision
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     * @param images (string)
     * Recibe el nombre de la imagen.
     */

    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };



    /**
     * @memberof FormNuevaAdmision
     * @description
     */
    onFinish = (values) => {

        const { residente } = this.state;

        console.log(values);

        axios.post('admision/add',
            {
                id: ((this.props.admision_id !== undefined) ? this.props.admision_id : null),

                marca: ((values.marca !== undefined) ? values.marca : null),
                modelo: ((values.modelo !== undefined) ? values.modelo : null),

                datos: values.datos,
                tipo: this.state.tipo,
                status: null,
                asunto_id: values.asunto,
                casa_id: values.casa,
                descripcion: values.descripcion,
                colono_id: residente?._id,

                //visitante info
                nombre: values.nombre,
                // fecha_nacimiento: values.fecha_nacimiento,
                visitante_id: null,
                comprobantes: values?.comprobantes?.map(e => e.name),

            },
            {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then(res => {
                message.success('¡Admision creada!');
                this.props.hideModal();

            })
            .catch(error => {
                console.log('solicitud admision no se pudo guardar', error);
            })
    }


    render() {

        return (
            <Form onFinish={this.onFinish} className="modal-form" >
                <Row>
                    <Col span={24} className='modal-header'>
                        <Button className="btn-close-modal" onClick={this.props.hideModal}>
                            <IconClose />
                        </Button>
                        <img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal" />
                    </Col>
                    <Col span={24} className="center mt-1 mb-1">
                        <Title level={3} className="modal-title">
                            {(this.state.tipo == 0) ? "Peaton Admisión" : "Vehiculo Admisión"}
                        </Title>
                    </Col>
                </Row>

                {(this.props.admision_id === null) ?
                    <Row align="center" gutter={[16, 16]}>
                        <Col span={12} offset={6}>
                            <Form.Item name="tipo" label="Tipo de Admisión" >
                                <Radio.Group onChange={(e) => this.onChangetipo(e)} defaultValue={this.state.tipo} value={this.state.tipo}>
                                    <Radio value={0}><label style={{ color: "#899aa4" }}>Peatonal  </label> </Radio>
                                    <Radio value={1}><label style={{ color: "#899aa4" }}>Vehicular </label> </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    : null}

                <Row align="center" gutter={[16, 16]}>
                    <Col span={11}>
                        <Form.Item name="nombre" label="Nombre Visita" rules={[{ required: true, message: "Debe escribir el nombre del visitante." }]}>
                            <Input className="input-modal" />
                        </Form.Item>
                    </Col>
                    <Col span={11} >
                        <Form.Item name="hora_entrada" label="Fecha y Hora" >
                            <Text className="modal-text" >{moment(this.state.hora_entrada).format('DD/MM/YYYY, hh:mm:ss A')}</Text>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="center" gutter={[16, 16]}>
                    <Col span={11}>
                        <Form.Item name="asunto" label="Asunto" rules={[{ required: true, message: "Debe seleccionar un asunto." }]} >
                            <Select
                                className="input-modal"
                                placeholder="Buscar.."
                                showSearch
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={(search) => this.getAsuntos(search)}
                                // onSelect={(e) => this.o(e)}
                                notFoundContent={<p>No se encontro el Asunto</p>}
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Input style={{ flex: 'auto' }} ref={this.asuntosRef} />
                                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.addAsunto} >
                                                <PlusOutlined /> Añadir
                                            </a>
                                        </div>
                                    </div>
                                )}
                            >
                                {this.state.asuntos.map(function (asunto, index) {
                                    return <Option value={asunto._id}>{asunto.nombre}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11} >
                        <Form.Item name="casa" label="Casa" rules={[{ required: true, message: "Debe seleccionar una casa." }]} >
                            <Select className="select-modal" suffixIcon={<IconSelect />} onChange={this.onChangeCasa}>
                                {this.state.casas.map(casa => {
                                    return <Option value={casa._id}>{casa.nombre}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {this.renderResidenteInfo()}

                {this.renderTipo()}



                <Row align="center" justify="space-around">
                    <Col span={24} className="center">
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="btn-green"> Guardar </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        );
    }


}

