import React, { Component } from "react";
import { Form, Input, Button, Layout, Select, Switch, Row, Col, Modal, message, List, PageHeader, Typography } from 'antd';
import { Icon } from '@ant-design/compatible';

//componentes
import { IconSelectArrowModal, IconClose, IconSelect } from './../Widgets/Iconos';
//css
import '../global/css/modalesTEMP.css'

const { Option } = Select;
const { Title, Text } = Typography;
const axios = require('axios').default;

export default class ModalUsuarios extends Component {

 	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			roles: [],
			casas: [],
			jefe: false,
		}
	}

	/**
	* @memberof ModalUsuarios
	*
	* @method componentDidMount
	* @description  Al montar el componente trae los roles y casas
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.GetRoles();
		this.GetCasas();
	}


	/**
	* @memberof ModalUsuarios
	*
	* @method componentDidUpdate
	* @description  
	*
	**/
	componentDidUpdate = () => {
	
	}


	/**
	* @memberof ModalUsuarios
	*
	* @method GetRoles
	* @description  Consulta que trae todos la lista de roles
	*
	**/
	GetRoles = async () => {
		axios.get("/roles", {})
		.then(res => {
			this.setState({ roles: res.data })
		}).catch(res => {
			console.log("lista de roles consulta", res);
		})
	}


	/**
	* @memberof ModalUsuarios
	*
	* @method GetCasas
	* @description  Consulta que trae la lista de casas
	**/
	GetCasas = async () => {
		axios.get("/casas",{})
		.then(res => {
			this.setState({ casas: res.data.data })
		}).catch(res => {
			console.log("lista de casas consulta", res);
		})
	}


	/**
	* @memberof ModalUsuarios
	*
	* @method addUsuario
	* @description  Envia los datos del formulario al Servidor
	*
	**/
	onFinish = (values) => {
		if(this.props.modalTitle === "Editar"){
			this.updateUsuario(values)
		}else{
			this.addUsuario(values)
		}
	}


	/**
	* @memberof ModalUsuarios
	*
	* @method addUsuario
	* @description  Envia los datos del formulario al Servidor
	*
	**/
	addUsuario = (values) => {
		axios.post('/usuarios/registrar', {
			nombre: values.nombre,
			telefono: values.telefono,
			email: values.email,
			roles_id: values.tipoUsuario,
			casa_id: values.casa_id
		}).then((res) => {
			message.success("¡Usuario creado!");
			this.hideModal();
		}).catch((error) => {
			Modal.warning({
				title: 'Información de Registro Incorrecta',
				content: (Array.isArray(error.response.data.error)) ? (
				<List
					size="small"
					bordered
					dataSource={error.response.data.error}
					renderItem={field => (
						<List.Item> {field.message}</List.Item>
			  		)}
				/>) : 'Error al procesar registro. Verifique la información.'
			})
	  	})
	}

	/**
	* @memberof ModalUsuarios
	*
	* @method updateUsuario
	* @description Actuliza la informacion de un usuario
	**/
	updateUsuario = (values) => {
		axios.post('/usuarios/update', {
			id: this.props.usuario._id,
			nombre: values.nombre,
			telefono: values.telefono,
			email: values.email,
			roles_id: values.tipoUsuario,
			casa_id: values.casa_id
		}).then((res) => {
			message.success("¡Usuario actualizado!");
			this.hideModal();
		}).catch((error) => {
			Modal.warning({
				title: 'Información de Registro Incorrecta',
				content: (Array.isArray(error.response.data.error)) ? (
				<List
					size="small"
					bordered
					dataSource={error.response.data.error}
					renderItem={field => (
						<List.Item> {field.message}</List.Item>
			  		)}
				/>) : 'Error al procesar registro. Verifique la información.'
			})
	  	})
	}




	/**
	* @memberof ModalUsuarios
	*
	* @method GetCasas
	* @description  Consulta que trae la lista de casas
	**/
	hideModal = () => {
		this.setState({ visible: false, jefe: false, return: false })
		this.props.onCancel();
  	}



	render() {
		let { visible } = this.props;

		return (
			<Modal
				className="modal-aide"
				closable={false}
				footer={false}
				destroyOnClose={true}
				onCancel={this.hideModal}
				visible={visible}
				width={"600px"}
	  		>

		  		<Row>
					<Col span={24} className='modal-header'>
						<Button className="btn-close-modal" onClick={this.hideModal}>
							<IconClose/>	
						</Button>
						<img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal"/>
					</Col>
					<Col span={24} className="center">
						<Title  style={{fontSize:"26px"}} className="modal-title">{this.props.modalTitle} Usuario</Title>
					</Col>
				</Row>


				<Form 
					onFinish={this.onFinish} 
					className="modal-form "
					initialValues={this.props.usuario}
				>
					<Row>
						<Col xs={24} lg={11}>
							<Form.Item 
								name="nombre"
								label="Nombre" 
								rules={[{ required: true, message: "Ingrese nombre" }]} 
							>
				  				<Input className="input-form input-modal"/>
							</Form.Item >
			  			</Col>

			  			<Col xs={24} lg={{span: 11, offset: 2}}>
							<Form.Item 
								name="email"
								label="Email"
								rules={[{ required: true, message: "Ingrese email" }]} 
							>
								<Input className="input-modal" />
							</Form.Item >
			  			</Col>

						<Col xs={24} lg={11}>
							<Form.Item 
								name="telefono"
								label="Teléfono" 
								rules={[{ required: true, message: "Ingrese teléfono" }]}  >
								<Input className="input-modal" type="number" />
							</Form.Item >
						</Col>


						<Col xs={24} lg={{span: 11, offset: 2}}>
							<Form.Item
								label="Tipo de Usuario" 
								name="tipoUsuario" 
								rules={[{ required: true, message: "Seleccione tipo de usuario" }]}  
							>
								<Select 
									className="select-modal select-large" 
									suffixIcon={<IconSelect />} 
									placeholder="Seleccionar tipo de usuario" allowClear 
								>
									{this.state.roles.map((rol, index) => {
									  return <Option value={rol._id}>{rol.nombre}</Option>
									})}
				  				</Select>
							</Form.Item>
			  			</Col>

			  			<Col xs={24} lg={11}>
							<Form.Item
								label="Casa" 
								name="casa_id" 
								rules={[{ required: true, message: "Seleccione la casa del usuario" }]}  
							>
								<Select 
									className="select-modal select-large"
									suffixIcon={<IconSelect />} 
									placeholder="Seleccionar casa de usuario" allowClear 
								>
									{this.state.casas.map(function (casa, index) {
									  return <Option value={casa._id}>{casa.nombre}</Option>
									})}
			  					</Select>
							</Form.Item>
						</Col>

						<Col span={24} className="center">
							<Form.Item className="btn-form">
								<Button type="primary" htmlType="submit" className="btn-green"> Guardar </Button>
							</Form.Item>
			  			</Col>
					</Row>
		 		</Form>
			</Modal >
		);
	}
}


