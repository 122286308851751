import React, { Component } from 'react';
import { Modal, Row, Col, Button, Typography, Form, Input, message } from 'antd';
//componentes
import {IconClose, IconLock} from '../../../Widgets/Iconos'
//css
import '../../../global/css/modalesTEMP.css'

const { Title } = Typography;
const axios = require("axios").default;

export default class ModalTiposUsuarios extends Component {

	constructor(props) {
		super(props);
		this.state = {
		   
		}
	}

	componentDidMount(){
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
	}

	/**
    * @memberof ModalTiposUsuarios
    *
    * @method   hideModal
    * @description  Metodo para cerrar el modal
    *
    **/
	hideModal = () => {
		this.props.closeMethod();
	}

	/**
    * @memberof ModalTiposUsuarios
    *
    * @method   onFinish
    * @description  Metodo que se ejecuta al hacer submit al formulario
    *
    **/
	onFinish = (values) => {
		if(this.props.modalTitle === 'Editar'){
			this.updateTipo(values)
		}else{
			this.addTipo(values)
		}
	}


	/**
    * @memberof ModalTiposUsuarios
    *
    * @method   addTipo
    * @description  Metodo para agregar un tipo de usuario
    *
    **/
	addTipo = (values) => {
		axios.post('/roles/add',{
			nombre: values.nombre,
			descripcion: values.descripcion
		}).then(response => {
			message.success('¡Tipo de usuario agregado!')
			this.hideModal();
		}).catch(error => {
			message.error('Error al crear el Tipo de Usuario')
		})
	}

	/**
    * @memberof ModalTiposUsuarios
    *
    * @method   updateTipo
    * @description  Metodo para agregar un tipo de usuario
    *
    **/
	updateTipo = (values) => {
		axios.post('/roles/update',{
			id: this.props.tipo_usuario?._id,
			nombre: values.nombre,
			descripcion: values.descripcion
		}).then(response => {
			message.success('¡Tipo de usuario actualizado!')
			this.hideModal();
		}).catch(error => {
			message.error('Error al actualizar el Tipo de Usuario')
		})
	}




	render() {

		return (
			<Modal
				className="modal-aide"
				visible={this.props.visible}
				closable={false}
				footer={false}
				destroyOnClose={true}
				onCancel={this.hideModal}
			>
				<Row>
					<Col span={24} className='modal-header'>
						<Button className="btn-close-modal" onClick={this.hideModal}>
							<IconClose/>	
						</Button>
						<img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal"/>
					</Col>
					<Col span={24} className="center">
						<Title  style={{fontSize:"26px"}} className="modal-title">{this.props.modalTitle} Tipo de Usuario</Title>
					</Col>
				</Row>

				<Form
					onFinish={this.onFinish}
					className="modal-form"
					initialValues={this.props.tipo_usuario}
				>
					<Row align="center">
						<Col span={15}>
							<Form.Item
								name="nombre"
								label="Nombre"
								rules={[{ required: true, message: 'Por favor, ingrese el nombre' }]}
							>	
								<Input  className="input-modal input-form" />
							</Form.Item>
						</Col>
						<Col span={15}>
							<Form.Item
								name="descripcion"
								label="Descripcion"
								rules={[{ required: true, message: 'Por favor, ingrese la Descripcion' }]}
							>	
								<Input className="input-modal input-form"/>
							</Form.Item>
						</Col>
						<Col span={24} className="center">
							<Form.Item>
						        <Button type="primary" htmlType="submit" className="btn-green">
						         	Guardar
						        </Button>
						    </Form.Item>
						</Col>
					</Row>
				</Form>

			</Modal>
		);
	}
}


