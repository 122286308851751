//Axios config
const axios = require("axios").default;

 //const WebService = "http://localhost:4002"
const WebService = "https://aide.ws.parqueesmeralda.mx/"
console.log('process.env.NODE_ENV',process);

axios.defaults.baseURL = WebService + "/";
axios.defaults.headers.post["Content-Type"] = "application/json";

export {
    WebService
} 