//Dependencias
import React, { Component } from 'react';
import { Layout, Menu, Card, Avatar, Row, Col, PageHeader, Empty } from 'antd';
import { FaSquare } from 'react-icons/fa';
import { FaPlusCircle, FaPlus } from 'react-icons/fa';
import { Icon } from '@ant-design/compatible';
//assets
// import '../../global/css/Camaras.css';

const { Meta } = Card;

function TestCard() {
    return (
        <Card
            cover={
                <img
                    alt="example"
                    src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                />
            }
            actions={[
                <Icon type="setting" key="setting" />,
                <Icon type="edit" key="edit" />,
                <Icon type="ellipsis" key="ellipsis" />,
            ]}
        >
            <Meta
                avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                title="Card title"
                description="This is the description"
            />
        </Card>
    );

}

/**
 *
 *
 * @class Camaras
 * @extends {Component}
 */
class Camaras extends Component {
    render() {
        const { Content } = Layout;
        return (
            <Layout className="dashboard-section-layout  calendario-layout">
                <PageHeader className="dashboard-section-header nombreVista" title="Cámaras" >
                </PageHeader>
                <Content className="dashboard-section-content"></Content>
                    <Empty/>
                <Content>

                </Content>
            </Layout>
        );
    }
}


export default Camaras;
