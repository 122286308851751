//Dependencias
import React, { Component } from 'react';
import { Layout, Menu, message } from 'antd';
import { Link } from 'react-router-dom';
import { Icon } from '@ant-design/compatible';
//componentes
import {
    IconDashboard,
    IconAccessos,
    IconCalendario,
    IconCamaras,
    IconMapa,
    IconAsuntos,
    IconDB,
    IconUsuarios,
    IconSesion,
    IconReportes,
    IconTipos,
    IconHistorial,
} from '../../Widgets/Iconos'
//assets
import '../../global/css/Nav.css';

const axios = require('axios').default;
const { Sider } = Layout;


/**
 * @class SiderAdmin
 * @extends {Component}
 * @description Sidebar del Dashboard
 */
class SiderAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            userId: "",
            admin: true,
        };
    }

    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };


    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        let { admin } = this.state;
        return <Sider className="sidebar" trigger={null} collapsible collapsed={this.state.collapsed}>

            <Menu mode="inline" defaultSelectedKeys={['1']} className="nav-bar">

                <Menu.ItemGroup>
                    {/* <Menu.Item>
                        <Icon className="" type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} onClick={this.toggle} />
                    </Menu.Item> */}
                    <Menu.Item key="1" className="nav-item">
                        <IconDashboard />
                        <span className="nav-text">Overview</span>
                        <Link to="/admin/dashboard"></Link>
                    </Menu.Item>

                    <Menu.Item key="2" className="nav-item">
                        <IconAccessos />
                        <span className="nav-text">  Accesos </span>
                        <Link to="/admin/accesos" />
                    </Menu.Item>

                    {/* <Menu.Item key="3" className="nav-item">
                        <IconCalendario />
                        <span className="nav-text">  Calendario </span>
                        <Link to="/admin/calendario"> </Link>
                    </Menu.Item> */}

                    <Menu.Item key="4" className="nav-item">
                        <IconCamaras />
                        <span className="nav-text">Cámaras</span>
                        <Link to="/admin/camaras"> </Link>
                    </Menu.Item>

                    <Menu.Item key="5" className="nav-item">
                        <IconMapa />
                        <span className="nav-text">  Mapa </span>
                        <Link to="/admin/mapa"> </Link>
                    </Menu.Item>
                    </Menu.ItemGroup>

                    <Menu.ItemGroup>

                    <Menu.Item key="13" className="nav-item">
                        <IconAsuntos />
                        <span className="nav-text">  Asuntos </span>
                        <Link to="/admin/asuntos"> </Link>
                    </Menu.Item>
                    <Menu.Item key="6" className="nav-item">
                        <IconDB />
                        <span className="nav-text">Database</span>
                        <Link to="/admin/database"></Link>
                    </Menu.Item>

                    <Menu.Item key="7" className="nav-item">
                        <IconHistorial />
                        <span className="nav-text">Historial</span>
                        <Link to="/admin/historial"></Link>
                    </Menu.Item>


                    {(admin == true) ?
                        <Menu.Item key="8" className="nav-item">
                            <IconUsuarios />
                            <span className="nav-text">Usuarios </span>
                            <Link to="/admin/usuarios/" />
                        </Menu.Item>
                        : null}
                    {(admin == true) ?
                        <Menu.Item key="9" className="nav-item">
                            <IconTipos />
                            <span className="nav-text">Tipos de Usuarios</span>
                            <Link to="/admin/usuarios/tipos" />
                        </Menu.Item>
                        : null}
                    {/* <Menu.Item key="10" className="nav-item">
                        <IconReportes />
                        <span className="nav-text">Reportes</span>
                        <Link to="/admin/reportes/" />
                    </Menu.Item>*/}

                </Menu.ItemGroup>

                <Menu.ItemGroup className="group-bottom">

                    <Menu.Item key="12" className="nav-item mt-3 mb-2 text-gray" >
                        <IconSesion />
                        <span className="nav-text">Cerrar Sesión</span>
                        <Link to="/login" onClick={() => { sessionStorage.clear() }}></Link>
                    </Menu.Item>
                </Menu.ItemGroup>

            </Menu>
        </Sider>
    }
}

export { SiderAdmin };
