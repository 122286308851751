//Dependencias
import React from 'react';
import { Route, Switch ,Redirect} from 'react-router-dom';
//Componentes
import About from './componentes/Landing/About/about';
import Contact from './componentes/Landing/Contact/contact';
import Page404 from './componentes/Page404/page404';

import Login from "./componentes/Auth/login";
import PasswordForgot from "./componentes/Auth/PasswordForgot";
import PasswordRecovery from "./componentes/Auth/PasswordRecovery";
import { Layout } from 'antd';

import AdminRoutes from './admin_routes'

import 'antd/dist/antd.css';

const {  Content } = Layout;
const IsLogged = () => {
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('rol') === null || sessionStorage.getItem('token') === undefined || sessionStorage.getItem('rol') === undefined)
        return false;
    return true;
}

const AppRoutes = () =>

    <Layout>
        <Content>
            <Switch>
                {/* <Route exact path="/" >
                    <Redirect to="/login" />
                </Route> */}
                <Route exact path="/about" component={About}/>
                <Route exact path="/contact" component={Contact}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/password/forgot" component={PasswordForgot}/>
                <Route exact path="/password/recovery" component={PasswordRecovery}/>
                {/* <Route exact path="/solicitud" component={FormatoSolicitud}/> */}
                <AdminRoutes/>
                <Route component={Page404}/>
            </Switch>
        </Content>


    </Layout>


export default AppRoutes;
