//Dependencias
import React, { Component } from 'react';
import { Layout, Table, PageHeader, Col, Row, Input, message } from 'antd'
import { Link } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa';
import io from 'socket.io-client';
import PropTypes from 'prop-types';


import moment from 'moment'

import {reformat} from '../../LinkUrlGenerate'


const { Content } = Layout;
const { Search } = Input;


export default class Notificaciones extends Component {

  

  /**
   *
   *
   * @export
   * @class Camaras
   * @extends {Component}
   */

  state = {
    title: 'AIDE',
    itemsList: [],
    currentPage: 0,
    pageCount: 0,
    itemCount: 0,

    loading: true,
    userName: "",
    userType: ""

  }

  constructor(props) {
    super(props);
  }

  static propTypes = {
    title: PropTypes.string.isRequired
  };

  socket = io('http://localhost:4002');


  componentDidMount = async () => {

    if (sessionStorage.getItem('token') !== null) {
      let userName = sessionStorage.getItem('userName');
      let userType = sessionStorage.getItem('userType').toUpperCase();

      this.setState({
        userName: userName,
        userType: userType
      });
    }

    //Obtenemos la información del usuario que tiene un token guardado

    let classElement = this;

    this.socket.emit('initialize', sessionStorage.getItem('token'));

    this.socket.on('logged', function (msg) {
      //console.log('logged ->', msg)
    });


    this.socket.on('notifications', function (notifications) {
      classElement.setState({
        loading: true
      });


      var numPage = classElement.state.currentPage;
      var numReg = 1;
      var itemsList = [];


      if (notifications.itemsList != undefined) {

        var dataL = notifications.itemsList,
          data_prov = [],
          dataList = [],
          data = notifications.itemsList;


        if (numPage == null || numPage == 1) {
          numReg = 0
        }
        else if (numPage == 0) { numReg = 0 }

        else {
          numReg = ((numPage - 1) * 10)
        }

        for (let index = 0; index < dataL.length; index++) {
          const element = dataL[index];
          numReg = numReg + 1;

          data_prov.push({
            _id: element._id,
            index: numReg,
            nReg: numReg,
            fecha: moment(element.createdAt).format('DD/MM/YYYY  hh:mm A'), //String(element.createdAt).substring(0, 10),
            movimiento: (element.evento != undefined) ? element.evento.movimiento : "Evento",
            descripcion: (element.evento != undefined) ? element.evento.accion : "Movimiento"
          });
        }

        itemsList = data_prov;
      }


      //const itemsList = itemsList;
      const paginator = notifications.paginator;
      const currentItemsList = classElement.state.itemsList;
      const newItemsList = itemsList.concat(currentItemsList);

      classElement.setState({
        itemsList: newItemsList,
        currentPage: paginator.currentPage,

        total: paginator.itemCount,
        itemCount: paginator.itemCount,
        pageCount: paginator.pageCount,
        loading: false,
      });
    });



    this.socket.on('update-notifications', function (msg) {
      //console.log('update')
    });

    this.handlePageClick(1);
  }


  handlePageClick = async (page) => {
    console.log("handlePageClick");
    console.log("pagina", page);//2

    this.setState({
      itemsList: [],
      currentPage: page
    });

    this.socket.emit('notifications', {
      page: page
    });
  };


  render() {



    const columns = [
      {
        title: '#',
        dataIndex: 'nReg',
        key: 'nReg',
        width: '20px'
      },
      {
        title: 'Evento',
        dataIndex: 'movimiento',
        key: 'movimiento',
      },

      {
        title: 'Descripción',
        dataIndex: 'descripcion',
        render: (text, row) => reformat(text) 
      },
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
        //render: (text) => moment(text).format('YYYY/MM/DD'),
      }
    ];


    let { itemsList, loading } = this.state;
    return (
      <Layout className="dashboard-section-layout">
        <PageHeader className="dashboard-section-header nombreVista" title="">
          <Row type="flex" justify="space-between">
            <Col xs={18} lg={10}>
              <Search
                placeholder="Buscar "
                enterButton="Buscar"
                size="large"
                className="input-search input-filtro"
                style={{ width: "100%", borderRadius: '10px' }}
                onSearch={this.searchData}
                initialValues={{ remember: false }}
              />
            </Col>
            <Col xs={1} lg={9}  >
              
            </Col>
          </Row>
        </PageHeader>

        <Content className="dashboard-section-content">
          <div className="divForm">
            <Table
              columns={columns}
              dataSource={itemsList}
              loading={loading}
              className="blackTheme  tablaProveedores"
              pagination={false}

              pagination={{
                pageSize: 10,
                onChange: this.handlePageClick,
                itemCount: this.state.itemCount,

                current: this.state.currentPage,
                total: this.state.itemCount,
                className: "pagination-table"
              }}
            />
          </div>

        </Content>
      </Layout>
    );
  }
}

