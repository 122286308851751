import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Table, Form, Input, Layout, Row, Col, List, Pagination, Typography, Modal, message, Tag, PageHeader, Popconfirm, Space, Button } from 'antd';
//componentes
import FloatingButton from './../Widgets/FloatingButton/FloatingButton';
import { IconSearch } from './../Widgets/Iconos'
import ModalTipoUsuarios from '../Admin/Modales/TipoUsuarios/ModalTipoUsuarios'
//css
import '../global/css/Usuarios.css';

const axios = require("axios").default;
const { confirm } = Modal;
const { Content } = Layout;
const { Search } = Input;
const { Text } = Typography


/**
 *
 *
 * @export
 * @class UsuariosTipos
 * @extends {Component}
 */
export default class UsuariosTipos extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			tipos: [],
			modalTipoVisible: false,
			tipo_usuario: undefined,

			/**
			 * Control de la paginación.
			 * */
			currentPage: 1,
			itemCount: 1,
			pageCount: 1,
			perPage: 10,
			search: ''
		};
	}

	/**
	  * @memberof UsuariosTipos
	  *
	  * @method   componentDidMount
	  * @description  Metodo que carga los datos a la vista
	  *
	  **/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.receivedData();


		// 605a3f7b120da80ad4ab0a7e

		if (this.props.match.params.id) {
			this.setState({
				modaViewPermiso: true,
				permiso_id: this.props.match.params.id
			})
		}
	}

	/**
  * @memberof UsuariosTipos
  *
  * @method   receivedData
  * @description  Metodo que consulta la lista paginada de registros de la tabla Roles
  * 
  **/
	receivedData = async (page = 1) => {
		this.setState({ loading: true })
		axios.get('/rolesPaginado', {
			params: {
				page: page,
				limit: 10,
				search: this.state.search,
			}
		}).then(response => {
			this.setState({
				tipos: response.data.data.itemsList,
				page: response.data.data.paginator.currentPage,
				total: response.data.data.paginator.itemCount,
				loading: false,
			})
		}).catch(error => {
			console.log("error", error);
			message.error('Error al traer la informacion')
			this.setState({ loading: false })
		});
	}

	/**
	   * @memberof TiposUsuarios
	   *
	   * @method   searchData
	   * @description  Metodo que realiza la busqueda de usuarios
	   * @param values String   con la info a buscar, por defecto cadena vacia para busquedas
	   *
	   **/
	searchData = (values) => {
		this.setState({ search: values }, () => {
			this.receivedData(1);
		});

	}

	/**
	  * @memberof UsuariosTipos
	  *
	  * @method   handlePageClick
	  * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	  *
	  **/
	handlePageClick = (page,) => {
		this.receivedData(page)
	};

	/**
	  * @memberof UsuariosTipos
	  *
	  * @method   deleteTipo
	  * @description  Elimina un tipo de usuario
	  *
	  **/
	deleteTipo = (id) => {
		this.setState({ loading: true })
		axios({
			method: 'delete',
			url: '/roles/delete',
			headers: { Authorization: sessionStorage.getItem('token') },
			data: { id: id }
		}).then((response) => {
			message.success('Tipo de Usuario eliminado')
			this.receivedData()
		}).catch((error) => {
			if (error.response.status === 403) {
				message.error('El rol esta asignado a un usuario')
			} else {
				message.error('Error al eliminar el Tipo de Usuario')
			}
			this.setState({ loading: false })
		})
	};

	/**
	 * @memberof UsuariosTipos
	 *
	 * @method   hideModal
	 * @description  Metodo que cierra el modal
	 *
	 **/
	hideModal = () => {
		this.setState({ modalTipoVisible: false, tipo_usuario: undefined })
		this.receivedData(1)
	};

	render() {


		return (

			<Layout className="dashboard-section-layout">
				<Content className="dashboard-section-content"  >
					<div className="divForm">
						<Row className="pd-1 row-enc-vista"  >
							<Col xs={24} md={24} xl={6} xxl={6}  >
								<p className="nombreVista"  > Tipos de Usuarios</p>
							</Col>
							<Col xs={24} md={12} xl={18} xxl={18}    >
								<Search
									placeholder="Buscar tipo de usuarios"
									size="large"
									className="input-search input-filtro"
									style={{ width: "100%", borderRadius: '10px' }}
									onSearch={this.searchData}
									initialValues={{ remember: false }}
									prefix={<IconSearch />}
								/>

							</Col>
						</Row>

						<Row>
							<List
								className="list-item-access blackTheme"
								dataSource={this.state.tipos}
								header={
									<Row className="list-header" >
										<Col span={10} className="item-access-row-column">
											<Text ellipsis >Nombre</Text>
										</Col>
										<Col span={10} className="item-access-row-column">
											<Text ellipsis >Descripción</Text>
										</Col>
									</Row>
								}
								loading={this.state.loading}
								renderItem={item => {
									return <List.Item className="div-accesos-list ">
										<Row className="item-access-row"  >
											<Col span={1} className="item-access-row-column">
                                                <Text ellipsis className="muesca"></Text>
                                            </Col>
											<Col span={9} className="item-access-row-column">
												<Text ellipsis >{item.nombre}</Text>
											</Col>
											<Col span={10} className="item-access-row-column">
												<Text ellipsis >{item.descripcion}</Text>
											</Col>
											<Col span={4} className="item-access-row-column">
												<Space size="middle">
													<Button type="link" className="accion-text" title="Editar" onClick={() => { this.setState({ modalTipoVisible: true, tipo_usuario: item, modalTitle: 'Editar' }) }}    >Editar</Button>
													{
														(item.nombre !== "Administrador") ?
															<Popconfirm
																title="¿Quiere eliminar el tipo de usuario?"
																onConfirm={() => { this.deleteTipo(item._id) }}
																okText="Si"
																cancelText="No"
															>
																<Button type="link" className="accion-text " title="Eliminar"    >Eliminar</Button>

															</Popconfirm>
															:
															<Button type="link" className="accion-text DeleteIconUsu " title="No se puede eliminar" disabled   >Eliminar</Button>
													}
												</Space>
											</Col>
										</Row>
									</List.Item>
								}}
								pagination={false}
							/>
						</Row>

						<Row className="pd-1 " justify="end"  >
							<Pagination
								total={this.state.itemCount}
								current={this.state.currentPage}
								className="pagination-table"
								onChange={this.handlePageClick}
							/>
						</Row>

					</div>
				</Content>

				<ModalTipoUsuarios
					visible={this.state.modalTipoVisible}
					tipo_usuario={this.state.tipo_usuario}
					modalTitle={this.state.modalTitle}
					closeMethod={this.hideModal}
				/>

				<FloatingButton onClick={() => { this.setState({ modalTipoVisible: true, modalTitle: 'Crear' }) }} />

			</Layout>
		);
	}
}

