//Dependencias
import React, { Component } from 'react';
import { Modal, Row, Col, Table, Typography, List, Empty, Button } from 'antd';
import { Link } from 'react-router-dom';

import { reformat } from './../../LinkUrlGenerate';
import { IconGoTo } from './../../Widgets/Iconos'
//assets
import '../../global/css/Graficas.css';
import 'moment/locale/es';


import DetalleForm from '../Accesos/Formularios/Detalle';
import Title from 'antd/lib/skeleton/Title';


const axios = require('axios');
const { Text } = Typography;


const moment = require('moment');
moment.locale('es'); // aca ya esta en es


/**
 *
 *
 * @class UltimosAccesos
 * @extends {Component}
 */
class UltimosAccesos extends Component {
    constructor(props) {
        super(props);
        this.state = {

            currentPage: 1,
            limit: 2,
            pageCount: 0,
            data: [],
            admision_id: null,
            loading: false,

        }
    }


    /**
     *
     *
     * @memberof UltimosAccesos
     * @description consultas de informacion al API
     */
    componentDidMount() {
        this.getAllAccess();
    }


    /**
    *
    * @method UltimosAccesos
    * @description Obtiene todos los accesos 
    * */
    getAllAccess = (page, search = this.state.search) => {
        axios.get('/admisiones/paginacion', {
            params: {
                page: page,
                limit: this.state.limit,
                search: search,
            }
        })
            .then(e => {
                this.setState({
                    data: e.data.data.itemsList,
                    itemCount: e.data.data.paginator.itemCount,
                    currentPage: e.data.data.paginator.currentPage,
                    pageCount: e.data.data.paginator.pageCount,
                })
            })
            .catch(e => console.log(e))
            .finally(f => {
                this.setState({ loading: false })
            })
    };
    /**
    *
    *
    * @memberof UltimosAccesos
    * @description Abre el modal de detalle del acceso
    */
    showDetalle = (id) => {
        this.setState({
            detalleVisible: true,
            admision_id: id,
        });
    };

    /**
   *
   *
   * @memberof UltimosAccesos
   * @description Metodo para cerrar modales
   * 
   */
    handleCancel = async (e) => {
        this.setState({
            detalleVisible: false,
            admision_id: null,
        });

        this.getAllAccess(1);
    };

    render() {
        return (
            <div className="div-accesos">
                <div>
                    <div className="div_titulo" style={{ marginLeft: "5px" }}> Últimos Accesos</div>
                    <Row className="row-titulo">
                        <Col span={4}><Text ellipsis className="ver-detalles txt-titulo"> NOMBRE </Text></Col>
                        <Col span={4}><Text ellipsis className="ver-detalles txt-titulo"> MÉTODO</Text></Col>
                        <Col span={4}><Text ellipsis className="ver-detalles txt-titulo"> INMUEBLE</Text></Col>
                        <Col span={4}><Text ellipsis className="ver-detalles txt-titulo"> FECHA & HORA</Text></Col>
                        <Col span={7}><Text ellipsis className="ver-detalles txt-titulo"> ASUNTO</Text></Col>
                    </Row>
                    <div className="div_contenido div-ultimosAccesos">

                        {(this.state.data.length > 0) ?
                            this.state.data.map(item => {
                                var clase = "spn-muesca ntf-nivel1"

                                if (item.evento?.relevancia === 1) {
                                    clase = "spn-muesca ntf-nivel1"
                                }
                                if (item.evento?.relevancia === 2) {
                                    clase = "spn-muesca ntf-nivel2"
                                }
                                if (item.evento?.relevancia === 3) {
                                    clase = "spn-muesca ntf-nivel3"
                                }
                                if (item.evento?.relevancia === 4) {
                                    clase = "spn-muesca ntf-nivel4"
                                }

                                return <Link onClick={() => { this.showDetalle(item._id) }}>
                                    <Row className="div-notificacion">
                                            <span className={clase}></span>
                                        <Col span={4}><Text ellipsis className="col-infoAcceso">{item?.nombre}</Text></Col>
                                        <Col span={4}><Text ellipsis className="col-infoAcceso">{item?.descripcion}</Text></Col>
                                        <Col span={4}><Text ellipsis className="col-infoAcceso">{item?.casa_id?.nombre}</Text></Col>
                                        <Col span={4}><Text ellipsis className="col-infoAcceso">{moment(item?.createdAt).format('MMMM Do YYYY')}</Text></Col>
                                        <Col span={7}> <span className="txt-infoAcceso"> {item?.asunto_id?.nombre} </span> </Col>
                                        {/* <Col span={4}><Text ellipsis className="ver-detalles">{moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text></Col> */}
                                        {/* <Col span={2}><Link  onClick={() => { this.showDetalle(item._id) }}>Ver Detalles</Link> </Col> */}
                                    </Row>
                                </Link>
                            })
                            :
                            <Empty description={"Sin Accesos"} style={{ height: '100%' }} />
                        }
                    </div>
                </div>

                <Modal
                    title="Detalle Acceso"
                    className="modal-aide top_up"
                    onCancel={this.handleCancel}
                    visible={this.state.detalleVisible}
                    destroyOnClose={true}
                    closable={false}
                    footer={false}
                    title={false}
                    width={"700px"}
                    footer={false}
                >
                    <DetalleForm id={this.state.admision_id} hideModal={this.handleCancel} />
                </Modal>
            </div>

        );
    }
}



/**
 *
 *
 * @class TodosLosAccesos
 * @extends {Component}
 */
class TodosLosAccesos extends Component {
    constructor(props) {
        super(props);
        this.state = { botonAdd: true, imagen: [] }
    }

    componentDidMount() {
        this.setState({ botonAdd: false });
    }

    render() {

        const dataAccesos = [
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',
            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            },
            {
                id: '{{Numero',
                visitante: '{{NombreVisitante}',
                metodoacceso: ' {{MetodoAcceso}',
                fecha: '{{Fecha/Hora',
                asunto: '{{Asunto',

            }
        ];
        const columnsAccesos = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',

            },
            {
                title: 'Nombre(s)',
                dataIndex: 'visitante',
                key: 'visitante',

            },
            {
                title: 'Metodo Acceso',
                dataIndex: 'metodoacceso',
                key: 'metodoacceso',
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
            },
            {
                title: 'Asunto',
                dataIndex: 'asunto',
                key: 'asunto',
            },
            {
                title: 'Acciones',
                key: 'actions',
                render: (text, record) => (
                    <Link title="Detalles">Ver Detalles </Link>
                ),
            },
        ];
        return (
            <div className="div-contenedor">
                <Table columns={columnsAccesos} dataSource={dataAccesos} className="blackTheme tablaAccesos" pagination={{ className: "pagination-table" }} />
            </div>

        );
    }
}


/**
 *
 *
 * @class HistorialDeAccesos
 * @extends {Component}
 */
class HistorialDeAccesos extends Component {


    /**
     *
     *
     * @memberof HistorialDeAccesos
     */
    componentDidMount() {
        this.setState({ botonAdd: false });
        console.log('props', this.props);
    }


    /**
     *
     *
     * @returns String
     * @memberof HistorialDeAccesos
     */
    formatRepresentante() {
        let representante = "Sin representante";
        this.props.representantes.map(item => {
            if (item != null && item !== undefined && item.nombre != undefined) {
                representante = item.nombre;
            }
        })
        return representante;
    }
    render() {
        let { solicitud, representantes } = this.props;
        return (
            <div>
                <div className="div_titulo"> Historial de Accesos</div>
                <List
                    className="list-item-request "
                    dataSource={this.props.data}
                    pagination={
                        { className: "pagination-table" }
                    }
                    renderItem={item => (
                        <List.Item>
                            <div className="item-request-row">
                                <Row>
                                    <Col span={10}>
                                        <Text className="nombre-solicitud">{this.formatRepresentante()}</Text>
                                    </Col>
                                    <Col span={10}>
                                        <Text className="nombre-solicitud" ellipsis>{solicitud.descripcion}</Text>
                                    </Col>
                                    <Col span={4}>
                                        <Text className="nombre-solicitud">{moment(item.fecha_visita).format('DD/MM/YYYY hh:mm A')}</Text>
                                    </Col>
                                </Row>
                            </div>
                        </List.Item>
                    )}
                />
            </div>
        );
    }
}




/**
 *
 *
 * @class Notificaciones
 * @extends {Component}
 */
class Notificaciones extends Component {

    componentDidMount() {
        this.setState({ botonAdd: false });
    }



    renderRowNotificacion(notif, index) {
        var clase = "span-ntf"
        let accion = "Nuevo evento"


        if (notif.evento !== undefined && notif.evento.accion !== undefined) {

            if(notif.evento.accion != undefined) {
                if(notif?.evento.accion.includes("{")) {

                    if(notif?.evento.accion.includes("@")) {
                        accion =   notif.evento?.accion.split('{')[0];
                    }
                    else{
                        accion = notif.evento?.accion.split('|')[0].replace("{"," ");
                    }
                }
                else{
                accion = notif.evento?.accion 

                }
            }
            else{
                accion = "Evento"
            }

            // accion = (notif.evento.accion != undefined) ? (notif.evento?.accion.includes("{")) ? notif.evento?.accion.split('con')[0] : notif.evento?.accion : "Evento";
        }

        
        
        if(notif?.nivel === undefined || notif?.nivel === 1) { clase = "span-ntf nivel1"}
        if(notif?.nivel === 2) { clase = "span-ntf nivel2"}
        if(notif?.nivel === 3) { clase = "span-ntf nivel3"}

        return <Row className="row-notificacion">
            <Col className = "col-ntf-ico"  span={ 4 } md={3}  xxl={ 2}>
                <span className={clase}></span>
            </Col>
            <Col className = "col-ntf-info" span={ 16 }md={18} xxl={20 }>
                <strong> { notif?.evento?.movimiento}</strong>
                <Text>{accion}</Text>
                <p>{
                    this.FormatoFecha(
                        moment(new Date(notif.createdAt.toString()), 'DD-MM-YYYU HH:mm:ss').fromNow()
                        )
                }</p>

            </Col>
            <Col className = "col-ntf-btn"  span={ 4 } md={3}  xxl={ 2}>
                <Button className="btn-goto-notificacion">
                    <IconGoTo />
                </Button>
            </Col>
        </Row>
    }

     FormatoFecha(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    render() {
        let { notificacion, notificaciones } = this.props;
        return (
            <div>
                <List
                    className="list-notifications"
                    dataSource={this.props.data}
                    pagination={false}
                    renderItem={(item, index) => {
                        
                        return <List.Item>
                            {this.renderRowNotificacion(item, index)}
                        </List.Item>
                    }}
                />
            </div>
        );
    }
}


export { UltimosAccesos, TodosLosAccesos, HistorialDeAccesos, Notificaciones };
