import React, { Component } from 'react';
import { Modal, Row, Col, Button, Typography, Form, Input } from 'antd';
//componentes
import { IconSuccess } from '../../Widgets/Iconos'
//css
import '../../global/css/modalesTEMP.css'

const { Title, Text } = Typography;
const moment = require('moment');

const axios = require('axios');
class ModalAutorizada extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {}
		}
	}


	/**
	 *
	 *
	 * @memberof ModalAutorizada
	 */
	componentDidMount() {
		console.log('admision creada y acceso autorizado', this.props);
		this.getAdmision();
	}

	/**
	 *
	 *
	 * @memberof Header
	 * @description Recibimos el ID de admisión.
	 */
	getAdmision = (id = this.props.asistencia) => {
		console.log('trayendo datos de admision a modal solicitud')
		this.setState({ loading: true })
		axios.get('/admision/autorizada', {
			params: {
				id
			}
		})
			.then(({ data }) => this.setState({ form: data.data }))
			.catch(error => { console.log('error al traer datos de la admsion/asistencia', error) })
			.finally(() => this.setState({ loading: false }))


		console.log('state', this.state);
	}

	hideModal = () => {
		this.props.hideModal();
	}


	render() {
		const { form } = this.state;
		return (
			<div>
				<Row>
					<Col span={24} className='modal-header'>
						<img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal" />
					</Col>
					<Col span={24} className="center mt-1">
						<Title level={3} className="modal-title">{this.props.tipo} Autorizada</Title>
					</Col>
				</Row>

				<Row align="center">
					<Col span={24} className='modal-header mb-1'>
						<IconSuccess />
					</Col>
					<Col span={15} className="center mb-2">
						<Title level={4} className="modal-sub-title">
							{form?.tipo === 0 ? 'Esta persona tiene el acceso permitido'
								: 'Este vehiculo tiene el acceso permitido'}
						</Title>
					</Col>
				</Row>

				<Form className="modal-form" >
					{form?.tipo === 0 ? <div>
						<Row align="center ">

							{(form.visitante_id != undefined) ?
							<Col span={11} className="">
								<Form.Item name="nombre" label="Nombre Completo" >
									<Text className="modal-text">{(form?.visitante_id?.nombre)}</Text>
								</Form.Item>
							</Col> : null
							}
							<Col span={11} offset={1} className="">
								<Form.Item name="time" label="Fecha y Hora">
									<Text className="modal-text">{moment(form?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text>
								</Form.Item>
							</Col>
						</Row>
						<Row align="center">
							<Col span={11}>
								<Form.Item name="casa" label="Casa" >
									<Text className="modal-text">{form?.casa_id?.nombre}</Text>
								</Form.Item>
							</Col>
							{(form.asunto_id != undefined) ?
								<Col span={11} offset={1}>
									<Form.Item name="asunto" label="Asunto">
										<Text className="modal-text">{form?.asunto_id?.nombre}</Text>
									</Form.Item>
								</Col>
								: null}
						</Row>

						{(form.visitante_id != undefined) ?
						<Row>
							<Col span={11 } style={{ marginLeft: '12px' }}>
								<Form.Item name="comprobantes" label="Comprobantes" >
									<img width={100} className="modal-text" alt={'Imagen Visitante'}  src={axios.defaults.baseURL + 'upload/' +form?.visitante_id?.comprobantes} />
								</Form.Item>
							</Col>
						</Row>
						:null}
					</div>
						:
						<div>
							<Row align="center ">
								<Col span={11} className="">
									<Form.Item name="casa" label="Casa" >
										<Text className="modal-text">{form?.casa_id?.nombre}</Text>
									</Form.Item>
								</Col>
								<Col span={11} offset={1} className="">
									<Form.Item name="hora_entrada" label="Fecha y Hora" >
										<Text className="modal-text">{moment(form?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text>
									</Form.Item>
								</Col>
							</Row>
							<Row align="center">
								<Col span={11}>
									<Form.Item name="descripcion" label="Descripción" >
										<Text className="modal-text">{form?.descripcion}</Text>
									</Form.Item>
								</Col>
								<Col span={11} offset={1}>
									<Form.Item name="datos" label="Placas">
										<Text className="modal-text">{form?.datos}</Text>
									</Form.Item>
								</Col>
							</Row>
						</div>}

				</Form>

				<Row className="center">
					<Button type="primary" htmlType="submit" className="btn-green" onClick={this.hideModal}>
						Cerrar
			        </Button>
				</Row>

			</div>
		);
	}
}




/**
*
*
* @export
* @param {*} props
* @returns
*/
export default function (props) {

	return <Modal
		className="modal-aide"
		visible={props.visible}
		closable={false}
		footer={false}
		destroyOnClose={true}
		width={650}
	>

		<ModalAutorizada {...props} />
	</Modal>
}