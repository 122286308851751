import React, { Component } from "react"
import { Input, Row, Col, Modal, Button, Form as AntdForm } from 'antd';
import { Redirect } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const axios = require('axios').default;


/**
 *
 *
 * @class PasswordRecovery
 * @extends {Component}
 */
class PasswordRecovery extends Component {

    recovery = React.createRef();
    url = new URL(window.location.href);

    constructor(props) {
        super(props)
        this.state = {
            return: false
        }
    }


    async componentDidMount() {
        try {

            let email = this.url.searchParams.get('email');
            if (email === null || this.url.searchParams.get('token') === null)
                this.setState({ return: true });

            this.recovery.current.setFieldsValue({
                email: this.url.searchParams.get('email')
            });

        } catch (e) {
            console.log('e', e)
        }

    }

    /**
   * @memberof PasswordRecovery
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al producto
   *
   * @returns response (array)
   **/
    handleSubmit = value => {
        value.email = this.url.searchParams.get('email');
        value.token = this.url.searchParams.get('token');
        axios.put('/password/update', value)
            .then(success => {
                console.log(success)
                Modal.success({
                    title: 'Actualizado.',
                    content: 'Se ha actualizado la contraseña.!',
                    onOk: e => { e(); this.setState({ return: true }) }
                });

            })
            .catch(error => {
                console.log(error.response)
                Modal.warning({
                    title: 'Error',
                    content: 'No es posible actualizar.'
                });
            });
    };
    /**
 * @memberof PasswordRecovery
 *
 * @method renderRedirect
 * @description  Activa el redireccionamiento si el formulario se envio con exito
 *
 **/
    renderRedirect = e => ((this.state.return) ? <Redirect to="/login" /> : null);

    render() {
        return (
            <div className="Login">
                {this.renderRedirect()}
                <div>
                    <AntdForm
                        ref={this.recovery}
                        initialValues={{ remember: true }}
                        onFinish={this.handleSubmit}
                        layout={"vertical"} >
                        <Row className="login-form">
                            <Col xs={24} md={24} lg={24}>
                                <AntdForm.Item
                                    name="email"
                                    label="Email"
                                    rules={[{ required: true, message: 'Por favor ingresa tu  correo' }]}
                                >
                                    <Input
                                        prefix={<UserOutlined />}
                                        placeholder="E-mail"
                                        className="input-box"
                                    />
                                </AntdForm.Item>
                                <AntdForm.Item
                                    label="Contraseña"
                                    name="password"
                                    rules={[{ required: true, message: 'Por favor ingresa tu  contraseña' }]}>
                                    <Input
                                        prefix={<LockOutlined />}
                                        type="password"
                                        placeholder="Contraseña"
                                        className="input-box"
                                    />
                                </AntdForm.Item>
                                <AntdForm.Item
                                    name="confirm"
                                    label="Confirmar Contraseña"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor confirma tu contraseña!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Las contraseñas no coinciden.!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined />}
                                        type="password-confirm"
                                        placeholder="Confirmar Contraseña"
                                        className="input-box"
                                    />
                                </AntdForm.Item>
                                <AntdForm.Item>
                                    <Button type="primary" htmlType="submit" className="login-submit" >Recuperar</Button>
                                </AntdForm.Item>
                            </Col>
                        </Row>
                    </AntdForm>

                </div>
            </div>

        )
    }
}

export default PasswordRecovery
