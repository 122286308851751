import React, { Component } from "react";
import { Row, Col, Typography, Button, Image } from 'antd';

//componentes
import '../../../global/css/Accesos.css';
import { IconClip, IconEye, IconCopy, IconClose } from '../../../Widgets/Iconos';

const axios = require('axios');
const moment = require('moment');
const { Title, Text, Paragraph } = Typography;



/**
 * @export
 * @class DetalleForm
 * @extends {Component}
 */
export default class DetalleForm extends Component {

    state = {
        acceso: {
            nombre: 'Nombre',
            fecha: new Date(),
            asunto: 'Asunto',
            casa: 'Casa',
            tipo: 'Vehiculo',
            vehiculo: 'Vehiculo',
            marca: 'marca',
            modelo: 'modelo',
            placas: 'placas',
            cantidad: "0",
        }
    }
    /**
    *
    *
    * @memberof ModalDetalleAcceso
    */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        const id = this.props.id;
        this.getDetalle(id)
    }


    getDetalle = (id) => {
        this.setState({ loading: true })
        axios.get('admision/autorizada', {
            headers: { Authorization: sessionStorage.getItem('token') },
            params: { id: id }
        })
            .then(res => {
                console.log('res get admision', res);
                console.log('cantidad', res.data.cantidad);
                this.setState({
                    visible: true,
                    admision_id: id,
                    admision: res.data.data,
                    cantidad: res.data.cantidad.toString()

                });
            })
            .catch(error => {
                console.log('error al traer solicitud', error);
            })
            .finally(f => {
                this.setState({ loading: false });
            })
    }


    render() {
        const { admision, cantidad } = this.state;
        console.log('cantidad', cantidad);
        return (
            <div>
                <Row className="row-enc">
                    <Col span={24} className='modal-header'>
                        <Button className="btn-close-modal" onClick={this.props.hideModal}>
                            <IconClose />
                        </Button>
                        <img src={"/Logo-H.png"} alt="logo AIDE" className="logo-aide-modal" />
                    </Col>
                    <Col span={24} className="center">
                        <Title style={{ fontSize: "26px" }} className="modal-title">Detalle Acceso </Title>
                    </Col>
                </Row>

                <Row className="row-info">
                    <Row gutter={[8, 8]} width="100%">
                        <Col xs={24} md={12}>
                            <p> Nombre Visita <IconCopy /> </p>
                            <Text className="modal-text">  {admision?.nombre} </Text>
                        </Col>

                        <Col xs={24} md={12}>
                            <p>Fecha y Hora</p>
                            <Text className="modal-text"> {moment(admision?.createAt).format('MMMM Do YYYY, h:mm:ss a')} </Text>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]} width="100%">
                        <Col xs={24} md={12}>
                            <p>Asunto</p>
                            <Paragraph className="modal-text" > {admision?.asunto_id?.nombre} </Paragraph>
                        </Col>
                        <Col xs={24} md={12}>
                            <p>Casa</p>
                            <Text className="modal-text"> {admision?.casa_id?.nombre} </Text>
                        </Col>
                    </Row>

                    <Row gutter={[8, 8]} width="100%">
                        {(admision?.tipo === 1) ?
                            <Row>
                                <Col xs={24} md={12}>
                                    <p>Placas</p>
                                    <Text className="modal-text"> {admision?.datos} </Text>
                                </Col>
                                <Col xs={24} md={12}>
                                    <p>Marca</p>
                                    <Text className="modal-text"> {admision?.marca} </Text>
                                </Col>
                                <Col xs={24} md={12}>
                                    <p>Modelo</p>
                                    <Text className="modal-text"> {admision?.modelo} </Text>
                                </Col>
                            </Row>
                            : null}



                        {(admision?.tipo === 0) ?
                            <Col xs={24} md={12}>
                                <p>Huella</p>
                                <Text className="modal-text"> {admision?.datos} </Text>
                            </Col>
                            : null}
                        <Col xs={24} md={12}>
                            <p> Identificación <IconClip /> </p>
                            {(admision?.visitante_id?.comprobantes?.length > 0) ?
                                <Image className="modal-text" width={200} src={axios.defaults.baseURL + 'upload/' + admision?.visitante_id?.comprobantes[0]} preview={true} />
                                : <Text className="modal-text">Sin comprobantes </Text>}
                        </Col>
                        <Col xs={24} md={12}>
                            <p> Número de Visitas </p>
                            <Text className="modal-text">{cantidad}</Text>
                        </Col>
                    </Row>

                </Row>

            </div>)
    }

}